import { TState } from '../../reducers'
import { computeStyle } from './styleParser'
import { Map } from 'immutable'
import { Dispatch } from '@reduxjs/toolkit'

export function detectTheming(value: string) {
   return typeof value === 'string' && (value[0] === '@' || value.indexOf('@') >= 0)
}

// TODO :interface definition
export interface getStyleForPlatformProps {
   styling: any
   parent: any   
   windowDimensions: any
   componentId: string
}

export interface triggerChangeStyles {
   parentStyle: any
   windowDimensions: any
   componentId: any
   properties: any
   localProperties: any
}


export function calcWH(
   style: { width?: number | string, height?: number | string } = {}, 
   { width: parentWidth, height: parentHeight }: { width: number, height: number }
) {
   const { width: styleWidth, height: styleHeight } = style

   const res: { width?: number, height?: number } = {}

   if (styleWidth) {
      res.width = 
         (styleWidth && typeof styleWidth === 'string')
         ? (styleWidth.includes('%')
            ? (parentWidth / 100) * parseInt(styleWidth)
            : parseInt(styleWidth)
         ) : parentWidth
   }

   if (styleHeight) {
      res.height = styleHeight && typeof styleHeight === 'string'
            ? styleHeight.includes('%')
               ? (parentHeight / 100) * parseInt(styleHeight)
               : parseInt(styleHeight)
            : parentHeight
   }

   return res
}


export const parseComponentStyle = (
   component: Map<string, any>,
   parent?: Map<string, any>,
) => (
   dispatch: Dispatch<any>,
   getState: () => TState
) => {
   const designSystem = getState().project.getIn(
      ['definition', 'design_system']
   ) as Map<string, any>
   
   return computeStyle({
      styling: component.get('computedStyle'),
      parentStyle: parent?.get('computedStyle') ?? Map(),
      designSystem
   });
}