import { CommonActions, createNavigationContainerRef } from '@react-navigation/native';
import { Dispatch } from 'react';
import { addParamToUrl, removeParamsFromUrlExceptProjectId } from './urlActions'

export const navigationRef = createNavigationContainerRef();

export const navigate = (routeName: string, params?: object) => {
   if (navigationRef.isReady()) {
      navigationRef.dispatch(CommonActions.navigate(routeName, params));
   }
};


export function navigateToScreen(target: string, componentId: string) {
   return (dispatch: Dispatch<any>, getState: () => Record<string, any>) => {
      const state = getState()

      const composite_key = state.project?.getIn(['definition', 'atoms', componentId, 'composite_key'])

      // TODO remove composite_key from app

      const navigation = state.local?.getIn(['screens', composite_key.first(), 'navigation'])

      removeParamsFromUrlExceptProjectId()
      const updatedUrl = addParamToUrl('screen', target)
      history.pushState({}, '', updatedUrl)

      navigation.navigate(target)
   }
}