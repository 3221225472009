import { Map, List } from 'immutable'

import { realtimeDB, storage } from './firebase'
import { parseExpression } from '../hooks/useInlineLogic'
import { updateCache } from '../reducers/LocalDataSlice'
import uuid from 'react-native-uuid'
import { Tconstraint } from '../types'

export { dbCreate, dbRead, dbWatch, dbUpdate, dbDelete, dbUploadFile }

function dbCreate(node: any, nestingAncestry: List<any>) {
   return (dispatch: any, getState: any) => {
      if (!node.getIn(['parameters', 'data'])) {
         console.error('Can`t create anything with empty data')
         return
      }

      const parsedData = node.getIn(['parameters', 'data']).reduce(
         (acc: Map<string, any>, value: any, key: string) => {
            return acc.set(
               dispatch(parseExpression(key, nestingAncestry)),
               dispatch(parseExpression(value, nestingAncestry))
            )
         },
         Map()
      )
      const parsedDataWithId = parsedData.set('uid', parsedData.get('uid', uuid.v4()))

      realtimeDB.create(
         '/data/' + getState().project.get('projectId') + '/' + node.getIn(['parameters', 'assetType']) as string,
         parsedDataWithId.toJS()
      )
   }
}

function dbWatch(parameters : Map<string, any>) { // reading a list of assets
   return (dispatch: any, getState: any) => {
      const state = getState();
      const projectId = state.project.get('projectId')
      realtimeDB.watch(
        '/data/' + projectId + '/' + parameters.getIn(['constraint', 'value']) as string, 
         (data: any) => {
            const cleanData = Object.keys(data).map((assetId: any) => ({
               uid: assetId,
               ...data[assetId]
            }))
            dispatch(updateCache({ key: parameters.hashCode(), value: cleanData }))
         },
         parameters.getIn(['constraint', 'value']) as string, 
         parameters.get('constraint').toJS() as Tconstraint, 
         {} 
      )
   }
}

function dbRead(parameters : Map<string, any>) { // reading a list of assets
   return (dispatch: any, getState: any) => {
      const state = getState();
      const projectId = state.project.get('projectId')
      realtimeDB.read(
         '/data/' + projectId + '/' + parameters.getIn(['constraint', 'value']) as string, 
         parameters.getIn(['constraint', 'value']) as string, 
         parameters.get('constraint').toJS() as Tconstraint, 
         {}
      ).then((data: any) => {
         const cleanData = Object.keys(data).map((assetId: any) => {
            return {
               uid: assetId,
               ...data[assetId],
            }
         })

         dispatch(updateCache({ key: parameters.hashCode(), value: cleanData }))
      }).catch((error: string) => {
         console.error('Failed to read DB', error)
      })
   }
}

export async function dbReadAsync(parameters : Map<string, any>) { // reading a list of assets
   return async (dispatch: any, getState: any) => {
      try {
         const state = getState();
         const projectId = state.project.get('projectId')
         const data = await realtimeDB.read(
            '/data/' + projectId + '/' + parameters.getIn(['constraint', 'value']) as string, 
            parameters.getIn(['constraint', 'value']) as string, 
            parameters.get('constraint').toJS() as Tconstraint, 
            {}
         )
         const cleanData = Object.keys(data).map((assetId: any) => {
            return {
               uid: assetId,
               ...data[assetId],
            }
         })
         return dispatch(updateCache({ key: parameters.hashCode(), value: cleanData }))
      } catch (error) {
         console.error('Failed to read DB', error)
      }
   }
}

function dbUpdate(node: any, nestingAncestry: List<any>) {
   return (dispatch: any, getState: any) => {
      const assetId = dispatch(parseExpression(node.get(['parameters', 'assetId']), nestingAncestry)) as string

      const parsedData = node.getIn(['parameters', 'data']).reduce(
         (acc: Map<string, any>, value: any, key: string) => {
            return acc.set(
               dispatch(parseExpression(key, nestingAncestry)),
               dispatch(parseExpression(value, nestingAncestry))
            )
         },
         Map()
      )

      realtimeDB.update(
         '/data/' + getState().project.get('projectId') + '/' + node.getIn(['parameters', 'assetType']) as string + '/' + assetId,
         parsedData.toJS()
      )
   }
}

function dbDelete(node: any, nestingAncestry: List<any>) {
   return (dispatch: any, getState: any) => {
      const assetId = dispatch(parseExpression(node.get(['parameters', 'assetId']), nestingAncestry)) as string
      realtimeDB.delete(
         '/data/' + getState().project.get('projectId') + '/' + node.getIn(['parameters', 'assetType']) as string + '/' + assetId
      )
   }
}

function dbUploadFile(node: any) {
   return (dispatch: any, getState: any) => {
      storage.uploadFile(node.getIn(['parameters', 'target']), node.getIn(['parameters', 'binary']))
   }
}