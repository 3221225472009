import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { ProjectReducer, LocalDataReducer, GlobalsReducer, UserDataReducer } from './reducers/index'

const store = configureStore({
   reducer: {
      project: ProjectReducer,
      userData: UserDataReducer,
      local: LocalDataReducer,
      globals: GlobalsReducer,
   },
   middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
         serializableCheck: false,
         immutableCheck: false,
      }).concat(thunk)//.concat(logger)
   },
   // logger
   devTools: process.env.NODE_ENV !== 'production',
})

export default store
