import React, { useEffect } from 'react';
import { Image as RNImage, ImageProps as _ImageProps, Dimensions } from 'react-native';
import { Map } from 'immutable';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';

import { calcWH } from '../../lib/styleHelper';
import { TImage } from '../../types';
import { ComponentStoreRecord } from '../../reducers';

// export type ImageProps = TImage & Pick<_ImageProps, 'style'>;

const window = Dimensions.get('window');

export interface IImageProps {
   component: Map<string, any>
   style: any
   parent: Map<string, any>
   nestingAncestry: any
}
const Image = (props: IImageProps) => {
   const { component, style, parent, nestingAncestry } = props;
   const defaultImageStyle = { flex: 1, objectFit: 'cover' };
   const defaultStyle = { flex: 1, textAlign: 'center' };


   // useEffect(() => {
   //    props.handleImageChanges(image, uid, dataCellRootId);
   // }, [nestingAncestry]);

   // if (icon) {
   //    switch (icon?.type || 'FontAwesome') {
   //       case 'FontAwesome':
   //          return (
   //             <FontAwesome
   //                style={[defaultStyle, style.image, { justifyContent: 'center' }]}
   //                iconStyle={[defaultStyle, style.image]}
   //                name={icon.src}
   //                size={style.height}
   //                color={icon.color}
   //             />
   //          );
   //       case 'MaterialIcons':
   //          return (
   //             <MaterialIcons
   //                style={[defaultStyle, style.image]}
   //                iconStyle={[defaultStyle, style.image]}
   //                name={icon.src}
   //                size={style.height}
   //                color={icon.color}
   //             />
   //          );
   //       default:
   //          return null;
   //    }
   // }

   return (
      <RNImage
         resizeMode='contain'
         style={[style.image, defaultImageStyle]}
         source={{ uri: component.getIn(['computedProperties', 'image']) as string }}
         // {...calcWH(style, parentLayout)}
      />
   );
};

export default Image;
