import React  from 'react';
import { Provider } from 'react-redux';
import { Map } from "immutable"

import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

// import store from './reducers';
import { ProjectReducer, UserDataReducer, LocalDataReducer, GlobalsReducer, EventsReducer} from './reducers/index';
import Entrypoint from './Entrypoint';


const store = configureStore({
   reducer: {
      project: ProjectReducer,
      userData: UserDataReducer,
      local: LocalDataReducer,
      globals: GlobalsReducer,
      events: EventsReducer
   },
   middleware: (getDefaultMiddleware: any) => {
      return getDefaultMiddleware({
         serializableCheck: false,
         immutableCheck: false,
      }).concat(thunk).concat(logger)
   },
   // logger
   devTools: process.env.NODE_ENV !== 'production',
})

export const Engine = ({schema, projectId }: { schema: Map<string, any>, projectId: string }) => {
   return (
      <Provider store={store}>
         <Entrypoint schema={schema} projectId={projectId} />
      </Provider>
   );
};

export default Engine;
