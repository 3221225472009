export const borderOptions = [
   'none',
   'solid',
   'dotted',
   'dashed',
   'double',
   'groove',
   'ridge',
   'inset',
   'outset',
]

export const distribution = {
   evenly: 'space-evenly',
   between: 'space-between',
   around: 'space-around',
   'flex-start': 'flex-start',
   'flex-end': 'flex-start',
   start: 'flex-start',
   center: 'center',
   end: 'flex-end',
}
export type TDistributionOptions = 'start' | 'center' | 'end' | 'between' | 'evenly'

export const alignments = {
   'start': 'flex-start',
   'flex-start': 'flex-start',
   center: 'center',
   end: 'flex-end',
   'flex-end': 'flex-start',
   stretch: 'stretch',
}
export type TAlignmentOptions = 'start' | 'center' | 'end' | 'stretch'
