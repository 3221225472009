import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Dimensions, LayoutChangeEvent, SafeAreaView, ScrollView, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { List, Map } from 'immutable';

import {
   setScreen,
   addScrollComponent,
   componentsLoaded,
   updateScreenLayout,
} from '../../reducers/LocalDataSlice';
import { ErrorBoundary } from '../ErrorBoundary';
import MetaComponent from '../MetaComponent';
import Loader from '../Loader';

const defaultLayout = {
   width: 0,
   height: 0,
   x: 0,
   y: 0
}

const ScreenComponent = (
   props: {
      navigation: Navigator,
      storeLayout?: { width: number, height: number, x: number, y: number },
      screen: any,
      appReady: boolean,
      displayType: string
   }
) => {
   const { navigation, screen, storeLayout, appReady, displayType} = props;
   const screenSections = screen.getIn(['layouts', displayType, "sections"], Map());
   const sections = useSelector((state: any) => state.project.getIn(['definition', 'sections']))
   const atoms = useSelector((state: any) => state.project.getIn(['definition', 'atoms']))

   const dispatch = useDispatch();
   const scrollRef = useRef(null);
   const [topLayout, setTopLayout] = useState(defaultLayout);
   const onTopLayout = (event: LayoutChangeEvent) => {
      const { width, height, x, y } = event.nativeEvent.layout;
      console.log(width, height, x, y)
      setTopLayout({
         width: Math.floor(width),
         height: Math.floor(height),
         x: Math.floor(x),
         y: Math.floor(y),
      })
   };

   const [bottomLayout, setBottomLayout] = useState(defaultLayout);
   const onBottomLayout = (event: LayoutChangeEvent) => {
      const { width, height, x, y } = event.nativeEvent.layout;
      setTopLayout({
         width: Math.floor(width),
         height: Math.floor(height),
         x: Math.floor(x),
         y: Math.floor(y),
      })
   };

   const onScreenLayout = (event: LayoutChangeEvent) => {
      const { width, height, x, y } = event.nativeEvent.layout;
      if (
         storeLayout?.width !== Math.floor(width) ||
         storeLayout?.height !== Math.floor(height) ||
         storeLayout?.x !== Math.floor(x)
      ) {
         dispatch(
            updateScreenLayout({
               width: Math.floor(width),
               height: Math.floor(height),
               x: Math.floor(x),
               y: Math.floor(y),
            })
         );
      }
   };

   useEffect(() => {
      dispatch(setScreen({ uid: screen.get('uid'), screen, navigation } as any));
      dispatch(componentsLoaded());
   }, []);

   useEffect(() => {
      if (appReady && scrollRef.current)
         dispatch(addScrollComponent(scrollRef.current));
   }, [appReady]);

   if (!appReady) {
      return <Loader />;
   }

   const topSections = screenSections.filter((section: any) => section.get('position') === 'top')
   const rightSections = screenSections.filter((section: any) => section.get('position') === 'right')
   const bottomSections = screenSections.filter((section: any) => section.get('position') === 'bottom')
   const leftSections = screenSections.filter((section: any) => section.get('position') === 'left')
   const autoSections = screenSections.filter((section: any) => section.get('position') === 'auto')
   const modalSections = screenSections.filter((section: any) => section.get('position') === 'modal')

   return (
      <SafeAreaView style={{ flex: 1 }}>
         <View
            onLayout={onTopLayout}
            style={{zIndex: 999, position: 'fixed', top: 0, width: '100%'}}>
            { topSections.size > 0 &&
               topSections.valueSeq()
                  .map((section: any) => {
                     const uid = sections.getIn([section.get('uid'), 'root_atom']);
                     return (<MetaComponent
                                 key={uid}
                                 componentId={uid}
                                 nestingAncestry={List()}
                                 />)
            })}
         </View>
         <View style={{zIndex: 998, position: 'absolute', left: 0 }}>
            { leftSections.size > 0 &&
               leftSections.valueSeq()
                  .map((section: any) => {
                     const uid = sections.getIn([section.get('uid'), 'root_atom']);
                     return (<MetaComponent
                                 key={uid}
                                 componentId={uid}
                                 nestingAncestry={List()}
                                 />)
            })}
         </View>
         <View style={{ zIndex: 998, position: 'fixed', right: 0 }}>
            { rightSections.size > 0 &&
               rightSections.valueSeq()
                  .map((section: any) => {
                     const uid = sections.getIn([section.get('uid'), 'root_atom']);
                     return (<MetaComponent
                                 key={uid}
                                 componentId={uid}
                                 nestingAncestry={List()}
                                 />)
            })}
         </View>
         <ScrollView
            ref={scrollRef}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{height: Dimensions.get('window').height }}
            style={{ zIndex: 997, top: topLayout.height, marginBottom: bottomLayout.height, flex: 1, height: Dimensions.get('window').height - topLayout.height - bottomLayout.height}}
            onLayout={onScreenLayout}>
            { autoSections.size > 0 &&
               autoSections.valueSeq()
                  .map((section: any) => {
                     const uid = sections.getIn([section.get('uid'), 'root_atom']);
                     return (<MetaComponent
                                 key={uid}
                                 componentId={uid}
                                 nestingAncestry={List()}
                                 />)
            })}
         </ScrollView>
         <View onLayout={onBottomLayout} style={{zIndex: 998, position: 'absolute', bottom: 0, width: '100%' }}>
            { bottomSections.size > 0 &&
               bottomSections.valueSeq()
                  .map((section: any) => {
                     const uid = sections.getIn([section.get('uid'), 'root_atom']);
                     return (<MetaComponent
                                 key={uid}
                                 componentId={uid}
                                 nestingAncestry={List()}
                                 />)
            })}
         </View>
            { modalSections.size > 0 &&
               modalSections.valueSeq()
                  .map((section: any) => {
                     const uid = sections.getIn([section.get('uid'), 'root_atom']);
                     return (
                        <View style={{zIndex: 999, position: 'fixed' }}>
                           <MetaComponent
                              key={uid}
                              componentId={uid}
                              nestingAncestry={List()}
                              />
                        </View>)
            })}
      </SafeAreaView>
   );
};

export default ScreenComponent;
