import React, { useEffect, useState } from 'react';
import { Text as RNText, StyleSheet, TextProps } from 'react-native';
import { Map } from 'immutable';

import { ComponentStoreRecord } from '../../reducers';
import { TText, TCreateComponentFuncAsProp } from '../../types';

export function parseText(text = '', style = {}): any {
   return (
      <RNText style={style}>
         {text}
      </RNText>
   );

   const parts = text.split(/\[b\]|\[\/b\]|\[color=#[a-f0-9]{6}\]|\[\/color\]/i);
   return parts.map((part: any, index: any) => {
      if (/\[color=#[a-f0-9]{6}\]/i.test(part)) {
         const color = (part.match(/\[color=(#[a-f0-9]{6})\]/i) || [''])[1];
         return parseText(part.replace(/\[color=#[a-f0-9]{6}\]/i, ''), { ...style, color });
      } else if (index % 2 === 0) {
         return (
            <RNText key={index} style={style}>
               {part}
            </RNText>
         );
      } else {
         return parseText(part, { ...style, fontWeight: 'bold' });
      }
   });
}

type TProps = TText & TCreateComponentFuncAsProp & Pick<TextProps, 'style'>;

interface ITextProps {
   component: Map<string, any> // ComponentStoreRecord
   style: any
   parent: Map<string, any> // ComponentStoreRecord
   nestingAncestry: any
   // handleTextChanges: (text: string, uid: string, dataCellRootId: string) => void
}
const Text = (props: ITextProps) => {
   const { component, style } = props;
   const text = component.getIn(['computedProperties', 'text'], '') as string;

   // TODO: investigate this. It seems unnecessary and/or a bad way to handle this
   // useEffect(() => {
   //    handleTextChanges(text, uid, dataCellRootId);
   // }, [nestingAncestry]);

   // return <RNText style={style.text}>{parseText(text)}</RNText>;
   return <RNText style={style.text}>{text}</RNText>;
};

const styles = StyleSheet.create({
   normal: {
      fontWeight: 'normal',
   },
   bold: {
      fontWeight: 'bold',
   },
});

export default Text;
