export { addParamToUrl, getParamsFromUrl, removeParamsFromUrlExceptProjectId }

function addParamToUrl(key: string, value: string) {
   const currentUrl = window.location.href;
   const urlObj = new URL(currentUrl);
   urlObj.searchParams.set(key, value);
   return urlObj.toString();
}

function getParamsFromUrl() {
   const currentUrl = window.location.href;
   const urlObj = new URL(currentUrl);
   const params: Record<string, any> = {};
   urlObj.searchParams.forEach((value, key) => {
      params[key] = value;
   });
   return params;
}

function removeParamsFromUrlExceptProjectId() {
   const currentUrl = window.location.href;
   const url = new URL(currentUrl);

   const projectId = url.searchParams.get('projectId');
   url.search = '';

   if (projectId) {
      url.searchParams.set('projectId', projectId);
   }

   window.history.replaceState({}, '', url.toString());
}
