import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import { useCallback, useContext, useEffect } from 'react';
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { Root } from './src/Root';

const App = () => {
   return (
      <GestureHandlerRootView style={styles.flex}>
         <SafeAreaProvider>
            <StatusBar style="light" translucent backgroundColor="transparent" />
            <Root />
         </SafeAreaProvider>
      </GestureHandlerRootView>
   );
};

const styles = StyleSheet.create({
   flex: {
      flex: 1,
   },
});

export default App;