import { Map, List } from 'immutable'

import { IInlineLogicComparison, IInlineLogicGet, IInlineLogicNode, IInlineLogicCombination } from '../../types'


const GetProperty = (params: any[], node: Map<string, any>) => (
   dispatch: any,
   getState: any
) => {
   return getState().local?.getIn(['atoms', params[0], 'computedProperties', node.getIn(['parameters', 'target'])]); 
}

const GetComponentData = (params: any[], node: Map<string, any>) => (
   dispatch: any,
   getState: any
) => {
   return getState().local?.getIn(['atoms', params[0], 'computedData', node.getIn(['parameters', 'target'])])
}

const GetAttribute = (params: any[], node: Map<string, any>) => ( // IInlineLogicNode<IInlineLogicGet>
   dispatch: any,
   getState: any
) => {
   return getState().local?.getIn(['atoms', params[0], 'computedProperties', node.getIn(['parameters', 'target'])]);
}

function GetLastItem(params: any[]) {
   if (Array.isArray(params[0]) && params[0].length > 0) {
      return params[0][params[0].length - 1];
   }
   if (List.isList(params[0])) {
      return params[0].last();
   }
   return undefined;
}

function Contains(params: any[], node: Map<string, any>) {
   if (Array.isArray(params[0]) || List.isList(params[0])) {
      return params[0].includes(node.getIn(['parameters', 'value']));
   }
   return undefined;
}

function Comparison(params: any[], node: Map<string, any>) { // IInlineLogicNode<IInlineLogicComparison>
   const [left, right] = params;
   switch (node.getIn(['parameters', 'comparator'])) {
      case '=':
         return left === right;
      case '!=':
         return left !== right;
      case '>':
         return left > right;
      case '<':
         return left < right;
      case '>=':
         return left >= right;
      case '<=':
         return left <= right;
      default:
         console.warn('Unhandled comparator', node.getIn(['parameters', 'comparator']));
         return false;
   }
}

function Combination(state: Record<string,any>, params: any[], node: IInlineLogicNode<IInlineLogicCombination>) {
   const [left, right] = params;
   switch (node.parameters.combinator) {
      case 'OR':
         return left || right;
      case 'AND':
         return left && right;
      default:
         console.warn('Unhandled combinator', node.parameters.combinator);
         return false;
   }
}

function UnderDevelopment(state: Record<string,any>, params: any[], node: IInlineLogicNode<any>) {
   return state.local?.getIn(['atoms', params[0], 'properties', node.parameters.name]);
}

export default { GetProperty, GetComponentData, GetAttribute, GetLastItem, Contains, Comparison, Combination, UnderDevelopment }
