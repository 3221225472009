import { createSlice } from '@reduxjs/toolkit';
import { Map, Record, RecordOf } from 'immutable'

export type TEventsSlice = {
   events: Map<string, boolean>
}
const defaultSliceState: TEventsSlice = {
   events: Map(),
}
const EventsSliceRecord: Record.Factory<TEventsSlice> = Record(defaultSliceState)
export type EventsSliceRecord = RecordOf<TEventsSlice>
const eventsSlice = createSlice({
   name: 'events',
   initialState: EventsSliceRecord(),
   reducers: {
      addNewEvent: (state: any, action: { payload: string }) => {
         return state.setIn(['events', action.payload], true)
      },
      removeEvent: (state: any, action: { payload: string }) => {
         return state.setIn(['events', action.payload], false)
      },
      clearAllEvents: (state: any) => {
         return state.set('events', Map());
      },
   },
});

export const EventsReducer = eventsSlice.reducer;
export const { addNewEvent, removeEvent, clearAllEvents } = eventsSlice.actions;
