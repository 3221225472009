import React from 'react';
import { useDispatch } from 'react-redux';
import { View, Modal as RNModal, ViewProps, StyleSheet, Dimensions } from 'react-native';
import { Map, List } from 'immutable';

import type { TBox, TComponent, TCreateComponentFuncAsProp } from '../../types';
import MetaComponent from '../MetaComponent/MetaContainer';
import { updateComponentProperties } from '../../reducers/LocalDataSlice';

// type TProps = TBox & TCreateComponentFuncAsProp & Pick<ViewProps, 'style'>;

export interface IChildInfo {
   uid: string,
   position: string
   order: number
}
type IModalProps = {
   isEditing: boolean,
   component: Map<string, any>,
   style: any,
   parent?: Map<string, any>,
   childrenInfo: List<IChildInfo>,
   nestingAncestry: any
}

const Modal = ({
   component,
   parent,
   style,
   childrenInfo,
   nestingAncestry,
   isEditing
}: IModalProps) => {
   const dispatch = useDispatch();
   
   const backgroundChildren = childrenInfo.filter(
      (childPosition: IChildInfo) => ['absolute', 'relative'].includes(childPosition.position)
   )

   const fixedChildren = childrenInfo.filter(
      (childPosition: IChildInfo) => childPosition.position === 'fixed'
   );

   const regularChildren = childrenInfo.filter(
      (childPosition: IChildInfo) => childPosition.position === 'auto'
   );

   const is_hidden = component.getIn(['computedProperties', 'is_hidden']) == 'true' || component.getIn(['computedProperties', 'is_hidden']) == true;

   const onToggleModal = () => {
      dispatch(
         updateComponentProperties({
            uid: component.getIn(['uid']),
            key: 'is_hidden',
            value: !is_hidden,
         })
      );
   };

   const ModalWrapper = isEditing ? View : RNModal;

   return (
      <ModalWrapper
         onBackdropPress={onToggleModal}
         onBackButtonPress={onToggleModal}
         animationType='fade'
         transparent
         style={[
            style?.layout,
            !isEditing ? { flex: 1 } : { height: Dimensions.get('window').height },
         ]}
         visible={!is_hidden}>
         <View style={styles.outerContainer}>
            {/* for fixed positioning */}
            {fixedChildren?.size > 0 &&
               fixedChildren
                  .sort((a: any, b: any) => (
                     parseInt(a.order as string) - 
                     parseInt(b.order as string)
                  ))
                  .map((child) => {
                     const suffix = component.getIn(['nestingAncestry', 'suffix']);
                     const uid = !suffix ? child.uid : `${component.get('uid')}_${suffix}`;
                     return (<MetaComponent
                                 key={uid}
                                 componentId={uid}
                                 parent={component}
                                 nestingAncestry={nestingAncestry}
                              />)
            })}
            {/* for absolute/relative positioning */}
            {backgroundChildren?.size > 0 && (
               <>
                  {backgroundChildren
                     .sort((a: any, b: any) => (
                        parseInt(a.order as string) - 
                        parseInt(b.order as string)
                     ))
                     .map((child) => {
                        const suffix = component.getIn(['nestingAncestry', 'suffix']);
                        const uid = !suffix ? child.uid : `${component.get('uid')}_${suffix}`;
                        return (<MetaComponent
                                    key={uid}
                                    componentId={uid}
                                    parent={component}
                                    nestingAncestry={nestingAncestry}
                                 />)
               })}
               </>
            )}
            {regularChildren?.size > 0 &&
               regularChildren
                  .sort((a: any, b: any) => (
                     parseInt(a.order as string) - 
                     parseInt(b.order as string)
                  ))
                  .map((child) => {
                     const suffix = component.getIn(['nestingAncestry', 'suffix']);
                     const uid = !suffix ? child.uid : `${component.get('uid')}_${suffix}`;
                     return (<MetaComponent
                                 key={uid}
                                 componentId={uid}
                                 parent={component}
                                 nestingAncestry={nestingAncestry}
                              />)
            })}
         </View>
      </ModalWrapper>
   );
};

export default Modal;

const styles = StyleSheet.create({
   outerContainer: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#20202080',
   },
});
