import { createSlice } from '@reduxjs/toolkit'
import { TDataModel } from '../types'

export type TUserDataModel = Record<string, TDataModel>

const userDataSlice = createSlice({
   name: 'userData',
   initialState: {},
   reducers: {
      setUserDataModel: (_, action) => {
         return action.payload
      },
      // ...
   },
})

export const UserDataReducer = userDataSlice.reducer;
export const { setUserDataModel } = userDataSlice.actions
