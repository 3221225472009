import { Dimensions, Platform } from 'react-native'
import { Map, List } from 'immutable'

import { TAlignmentOptions, TDistributionOptions, alignments, distribution } from './consts'
import { TCustomStyling } from '../../types/'
import { ComponentStylingRecord, NumericalValueRecord } from '../../types/records/styling'

const { width, height } = Dimensions.get('window');

export function computeStyle({
   styling,
   parentStyle,
   designSystem,
   windowDimensions
}: {
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
   windowDimensions?: any
}) {
   const styles = [
      widthStyle,
      heightStyle,
      shadowStyle,
      marginStyle,
      paddingStyle,
      borderStyle,
      radiusStyle,
      layoutStyle,
      positionStyle,
      textStyle,
      backgroundStyle,
      iconStyle,
   ].reduce(
      (acc, styleOperation) =>
         styleOperation({
            accStyles: acc,
            styling: styling ?? Map(),
            parentStyle,
            designSystem
         }),
      {}
   )
   return styles
}

function parseVal(
   dictValue: NumericalValueRecord,
   designSystem: any,
) {
   if(!dictValue) {
      // Useful for debugging
      // console.warn(`Couldn't parse empty ${typeof dictValue}`)
      // throw new Error(`No value provided: ${typeof dictValue}`)
      return 0
   }
   // TODO find a way to get design system instead of componentDef
   // Should create a hook?

   const responsiveFactor = designSystem.getIn(['responsive_factor']);
   try {
      switch (dictValue.get('unit', 'px')) {
         case '%':
            return `${dictValue.get('val', '0')}%`
         case 'rf':
            return Math.round(parseFloat(dictValue.get('val', '0')) * (responsiveFactor?.desktop || 12))
         case 'vh':
            return Math.round(height / 100 * parseInt(dictValue.get('val', '0')))
         case 'vw':
            return Math.round(width / 100 * parseInt(dictValue.get('val', '0')))
         case 'px':
         default:
            return parseInt(dictValue.get('val', '0'))
      }
   } catch (error: any) {
      console.warn(`Couldn't parse value: \n${JSON.stringify(dictValue)}\n`, error.message)
      return 0
   }
}

export function detectTheming(value: string) {
   return typeof value === 'string' && (value[0] === '@' || value.indexOf('@') >= 0)
}

function applyColor(
   value: any, 
   designSystem: any
) {
   const colours = designSystem.getIn(['colours']);

   if(!value)
      return;

   if (detectTheming(value)) {      
      const stringColor: any = value
      const match = stringColor.startsWith('@')
      if (match) {
         const propertyName = value.slice(value.indexOf('@') + 1)
         let replacementValue = colours.getIn([propertyName, 'color'], '')
         if (propertyName.endsWith('_rgb')) {
            replacementValue = (colours.getIn([propertyName.slice(0, propertyName.length - 4), 'colour']) as string)
               .replace('rgba', 'rgb')
               .replace(/,[^,]+$/, ')')
         }
         const newString = stringColor.replace(stringColor, replacementValue)
         if (newString) {
            return newString
         }
      }
   }

   return value
}

function widthStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {
   const tmpStyles: {
      width?: number | string
      flex?: number | string
      alignSelf?: string
      minWidth?: number | string
      maxWidth?: number | string
   } = {}

   switch (styling.getIn(['width', 'type']) as string) {
      case 'fixed': {
         if (styling.hasIn(['width', 'fixed'])) {
            tmpStyles['width'] = parseVal(styling.getIn(['width', 'fixed']) as NumericalValueRecord, designSystem) as number
         } 
         else if (styling.hasIn(['width', 'min'])) {
            tmpStyles['width'] = parseVal(styling.getIn(['width', 'min']) as NumericalValueRecord, designSystem)
         }
         break
      }
      case 'fit': {
         tmpStyles['width'] = 'fit-content'
         break
      }
      case 'expand':
      default:
         if (parentStyle.getIn(['layout', 'direction']) === 'row') {
            tmpStyles['flex'] = 1
         } else {
            tmpStyles['alignSelf'] = 'stretch'
         }
         break
   }

   if (styling.hasIn(['width', 'min', 'val'])) {
      tmpStyles['minWidth'] = parseVal(styling.getIn(['width', 'min'], 0) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['width', 'max', 'val'])) {
      tmpStyles['maxWidth'] = parseVal(styling.getIn(['width', 'max'], 0) as NumericalValueRecord, designSystem)
   }

   return { ...accStyles, ...tmpStyles }
}

function heightStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {
   const tmpStyles: {
      height?: number | string
      flex?: number | string
      alignSelf?: string
      minHeight?: number | string
      maxHeight?: number | string
      aspectRatio?: string
   } = {}

   switch (styling.getIn(['height', 'type'])) {
      case 'fixed': {
         if (styling.hasIn(['height', 'fixed'])) {
            tmpStyles['height'] = parseVal(styling.getIn(['height', 'fixed']) as NumericalValueRecord, designSystem) as number
         } else if (styling.getIn(['height', 'min'])) {
            tmpStyles['height'] = parseVal(styling.getIn(['height', 'min']) as NumericalValueRecord, designSystem)
         }
         break
      }
      case 'expand': {
         if (parentStyle?.getIn(['layout', 'direction']) === 'row') {
            tmpStyles.alignSelf = 'stretch'
         } else {
            tmpStyles.flex = 1
         }
         break
      }
      case 'ratio': {
         tmpStyles.aspectRatio =
            styling.getIn(['height', 'ratio', 'width'], 1) + ' / ' + styling.getIn(['height', 'ratio', 'height'], 1)
         break
      }
      case 'fit':
      default:
         break
   }

   if (styling.hasIn(['height', 'min', 'val'])) {
      tmpStyles['minHeight'] = parseVal(styling.getIn(['height', 'min'], 0) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['height', 'max', 'val'])) {
      tmpStyles['maxHeight'] = parseVal(styling.getIn(['height', 'max']) as NumericalValueRecord, designSystem)
   }

   return {...accStyles, ...tmpStyles}
}

function shadowStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {
   if (
      styling.has('shadow') && (
         styling.getIn(['shadow', 'width', 'val'], '0') !== '0' ||
         styling.getIn(['shadow', 'height', 'val'], '0') !== '0' ||
         styling.getIn(['shadow', 'blur', 'val'], '0') !== '0' ||
         styling.getIn(['shadow', 'color'], 'black') !== 'black'
      )
   ) {
      const offsetX = parseVal(styling.getIn(['shadow', 'width'], NumericalValueRecord()) as NumericalValueRecord, designSystem)
      const offsetY = parseVal(styling.getIn(['shadow', 'height'], NumericalValueRecord()) as NumericalValueRecord, designSystem)
      const blurRadius = parseVal(styling.getIn(['shadow', 'blur'], NumericalValueRecord()) as NumericalValueRecord, designSystem)
      const color = applyColor(styling.getIn(['shadow', 'color'], 'black'), designSystem)
      const boxShadow = `${offsetX} ${offsetY} ${blurRadius} ${color}`
      return { ...accStyles, boxShadow }
   }
   return accStyles
}

function marginStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {

   if (!styling.has('margin')) {
      return accStyles
   }

   const tmpStyles: {
      marginTop?: number | string
      marginLeft?: number | string
      marginBottom?: number | string
      marginRight?: number | string
   } = {}

   if (styling.hasIn(['margin', 'top'])) {
      tmpStyles.marginTop = parseVal(styling.getIn(['margin', 'top']) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['margin', 'left'])) {
      tmpStyles.marginLeft = parseVal(styling.getIn(['margin', 'left']) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['margin', 'bottom'])) {
      tmpStyles.marginBottom = parseVal(styling.getIn(['margin', 'bottom']) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['margin', 'right'])) {
      tmpStyles.marginRight = parseVal(styling.getIn(['margin', 'right']) as NumericalValueRecord, designSystem)
   }

   return {...accStyles, ...tmpStyles}
}

function paddingStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {
   if (!styling.has('padding')) {
      return accStyles
   }
   const tmpStyles: {
      paddingTop?: number | string
      paddingLeft?: number | string
      paddingBottom?: number | string
      paddingRight?: number | string
   } = {}

   if (styling.hasIn(['padding', 'top']) && styling.getIn(['padding', 'top', 'val']) !== '0') {
      tmpStyles.paddingTop = parseVal(styling.getIn(['padding', 'top']) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['padding', 'left']) && styling.getIn(['padding', 'left', 'val']) !== '0') {
      tmpStyles.paddingLeft = parseVal(styling.getIn(['padding', 'left']) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['padding', 'bottom']) && styling.getIn(['padding', 'bottom', 'val']) !== '0') {
      tmpStyles.paddingBottom = parseVal(styling.getIn(['padding', 'bottom']) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['padding', 'right']) && styling.getIn(['padding', 'right', 'val']) !== '0') {
      tmpStyles.paddingRight = parseVal(styling.getIn(['padding', 'right']) as NumericalValueRecord, designSystem)
   }

   return {...accStyles, ...tmpStyles}
}

function borderStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {
   if (!styling.has('border')) {
      return accStyles
   }

   const tmpStyles: {
      borderTopStyle?: string
      borderTopColor?: string
      borderTopWidth?: number | string
      borderLeftStyle?: string
      borderLeftColor?: string
      borderLeftWidth?: number | string
      borderBottomStyle?: string
      borderBottomColor?: string
      borderBottomWidth?: number | string
      borderRightStyle?: string
      borderRightColor?: string
      borderRightWidth?: number | string
   } = {}

   if (styling.hasIn(['border', 'top'])) {
      tmpStyles.borderTopStyle = styling.getIn(['border', 'top', 'style'], 'black') as string
      tmpStyles.borderTopColor = applyColor(styling.getIn(['border', 'top', 'color']), designSystem)
      tmpStyles.borderTopWidth = parseVal(styling.getIn(
         ['border', 'top', 'width'], 
         NumericalValueRecord({ val: '1', unit: 'px'})
      ) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['border', 'left'])) {
      tmpStyles.borderLeftStyle = styling.getIn(['border', 'left', 'style']) as string
      tmpStyles.borderLeftColor = applyColor(styling.getIn(['border', 'left', 'color']), designSystem)
      tmpStyles.borderLeftWidth = parseVal(styling.getIn(
         ['border', 'left', 'width'],
         NumericalValueRecord({ val: '1', unit: 'px'})
      ) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['border', 'bottom'])) {
      tmpStyles.borderBottomStyle = styling.getIn(['border', 'bottom', 'style']) as string
      tmpStyles.borderBottomColor = applyColor(styling.getIn(['border', 'bottom', 'color']), designSystem)
      tmpStyles.borderBottomWidth = parseVal(styling.getIn(
         ['border', 'bottom', 'width'],
         NumericalValueRecord({ val: '1', unit: 'px'})
      ) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['border', 'right'])) {
      tmpStyles.borderRightStyle = styling.getIn(['border', 'right', 'style']) as string
      tmpStyles.borderRightColor = applyColor(styling.getIn(['border', 'right', 'color']), designSystem)
      tmpStyles.borderRightWidth = parseVal(styling.getIn(
         ['border', 'right', 'width'],
         NumericalValueRecord({ val: '1', unit: 'px'})
      ) as NumericalValueRecord, designSystem)
   }

   return {...accStyles, ...tmpStyles}
}

function radiusStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {
   if (!styling.has('radius')) {
      return accStyles
   }
   const tmpStyles: {
      borderTopLeftRadius?: number | string
      borderTopRightRadius?: number | string
      borderBottomLeftRadius?: number | string
      borderBottomRightRadius?: number | string
      overflow?: string
   } = {}

   // TODO: clean that
   if(parseInt(styling.getIn(['radius', 'top_left', 'val']) as string) > 0)
      tmpStyles.borderTopLeftRadius = parseVal(styling.getIn(['radius', 'top_left']) as NumericalValueRecord, designSystem)
   if(parseInt(styling.getIn(['radius', 'top_right', 'val']) as string) > 0)
      tmpStyles.borderTopRightRadius = parseVal(styling.getIn(['radius', 'top_right']) as NumericalValueRecord, designSystem)
   if(parseInt(styling.getIn(['radius', 'bottom_left', 'val']) as string) > 0)
      tmpStyles.borderBottomLeftRadius = parseVal(styling.getIn(['radius', 'bottom_left']) as NumericalValueRecord, designSystem)
   if(parseInt(styling.getIn(['radius', 'bottom_right', 'val']) as string) > 0)
      tmpStyles.borderBottomRightRadius = parseVal(styling.getIn(['radius', 'bottom_right']) as NumericalValueRecord, designSystem)
   if (
      tmpStyles.borderTopLeftRadius ||
      tmpStyles.borderTopRightRadius ||
      tmpStyles.borderBottomLeftRadius ||
      tmpStyles.borderBottomRightRadius
   ) {
      tmpStyles.overflow = 'hidden'
   }

   return {...accStyles, ...tmpStyles}
}

function layoutStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {
   if (!styling.has('layout')) {
      return accStyles
   }
   const tmpStylesLayout: {
      flexDirection?: string
      flexWrap?: string
      justifyContent?: string
      alignItems?: string
      columnGap?: number | string
      rowGap?: number | string
   } = {}

   if (styling.hasIn(['layout', 'direction'])) {
      if (styling.getIn(['layout', 'direction']) === 'rowWrap') {
         tmpStylesLayout.flexDirection = 'row'
         tmpStylesLayout.flexWrap = 'wrap'
      } else if (styling.getIn(['layout', 'direction']) === 'columnWrap') {
         tmpStylesLayout.flexDirection = 'column'
         tmpStylesLayout.flexWrap = 'wrap'
      } else {
         tmpStylesLayout.flexDirection = styling.getIn(['layout', 'direction']) as string
      }
   }

   if (styling.hasIn(['layout', 'distribution'])) {
      tmpStylesLayout.justifyContent = distribution[styling.getIn(['layout', 'distribution']) as TDistributionOptions]
   }
   if (styling.hasIn(['layout', 'alignment'])) {
      tmpStylesLayout.alignItems = alignments[styling.getIn(['layout', 'alignment']) as TAlignmentOptions]
   }

   if (styling.hasIn(['layout', 'column_gap'])) {
      tmpStylesLayout.columnGap = parseVal(styling.getIn(['layout', 'column_gap']) as NumericalValueRecord, designSystem)
   }
   if (styling.hasIn(['layout', 'row_gap'])) {
      tmpStylesLayout.rowGap = parseVal(styling.getIn(['layout', 'row_gap']) as NumericalValueRecord, designSystem)
   }

   return {...accStyles, layout: tmpStylesLayout }
}

function positionStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {
   if (!styling.has('position')) {
      return accStyles
   }
   const tmpStyles: {
      position?: string
      zIndex?: number
      top?: number | string
      right?: number | string
      bottom?: number | string
      left?: number | string
      alignSelf?: string
      order?: number
      flex?: number
   } = {}

   switch (styling.getIn(['position', 'type'], 'auto') ) {
      case 'auto':
         if (styling.getIn(['position', 'auto', 'order'])) {
            tmpStyles.order = parseInt(styling.getIn(['position', 'auto', 'order']) as string)
         }
         if (styling.getIn(['position', 'auto', 'position'])) {
            tmpStyles.alignSelf = alignments[styling.getIn(['position', 'auto', 'position'], 'stretch')]
         }
         break
      case 'fixed':
         tmpStyles.position = 'absolute'
         tmpStyles.zIndex = 1
         if (styling.hasIn(['position', 'relative', 'top']))
            tmpStyles.top = parseVal(styling.getIn(['position', 'relative', 'top']) as NumericalValueRecord, designSystem)
         if (styling.hasIn(['position', 'relative', 'right']))
            tmpStyles.right = parseVal(styling.getIn(['position', 'relative', 'right']) as NumericalValueRecord, designSystem)
         if (styling.hasIn(['position', 'relative', 'bottom']))
            tmpStyles.bottom = parseVal(styling.getIn(['position', 'relative', 'bottom']) as NumericalValueRecord, designSystem)
         if (styling.hasIn(['position', 'relative', 'left']))
            tmpStyles.left = parseVal(styling.getIn(['position', 'relative', 'left']) as NumericalValueRecord, designSystem)
         break
      case 'relative':
         if (styling.hasIn(['position', 'relative', 'vertical'])) {
            tmpStyles.alignSelf = alignments[styling.getIn(['position', 'relative', 'vertical'], 'stretch') as TAlignmentOptions]
         }
         break
      case 'absolute':
         tmpStyles.top = parseVal(styling.getIn(['position', 'absolute', 'top']) as NumericalValueRecord, designSystem)
         tmpStyles.bottom = parseVal(styling.getIn(['position', 'absolute', 'bottom']) as NumericalValueRecord, designSystem)
         tmpStyles.left = parseVal(styling.getIn(['position', 'absolute', 'left']) as NumericalValueRecord, designSystem)
         break
      default:
         break
   }

   if (styling.getIn(['position', 'flexible'])) {
      tmpStyles.flex = styling.getIn(['position', 'flexible']) as number
   }

   return {...accStyles, ...tmpStyles}
}

function backgroundStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {
   if (!styling.has('background')) {
      return accStyles
   }
   const tmpStyles: {
      backgroundColor?: string
      image?: {
         uri: string
         style: Record<string, any>
      }
      gradient?: {
         colors: string[]
         start?: { x: number, y: number }
         end?: { x: number, y: number }
      }
   } = {}

   switch (styling.getIn(['background', 'type'])) {
      case 'color': {
         tmpStyles.backgroundColor = applyColor(styling.getIn(['background', 'color']), designSystem)
         break
      }
      case 'image': {
         tmpStyles.image = {
            uri: styling.getIn(['background', 'image', 'src']) as string,
            style: {
               alignItems: 'center',
               justifyContent: 'center',
            },
         }
         break
      }
      case 'gradient': {
         // const colors = styling.getIn(['background', 'gradient', 'colors || ['ffffff'];
         tmpStyles.gradient = {
            colors: (styling.getIn(['background', 'gradient', 'colors'], []) as List<string>).toArray() as string[]
         }
         // const strColors = colors.join(', ');
         switch (styling.getIn(['background', 'gradient', 'type'])) {
            case 'linear': {
               switch (styling.getIn(['background', 'gradient', 'direction'])) {
                  case 'to bottom':
                     tmpStyles.gradient.start = { x: 0, y: 0 }
                     tmpStyles.gradient.end = { x: 1, y: 1 }
                     break
                  case 'to left':
                     tmpStyles.gradient.start = { x: 1, y: 0.25 }
                     tmpStyles.gradient.end = { x: 0, y: 0.75 }
                     break
                  case 'to right':
                     tmpStyles.gradient.start = { x: 0, y: 0.75 }
                     tmpStyles.gradient.end = { x: 1, y: 0.25 }
                     break
                  case 'to top':
                     tmpStyles.gradient.start = { x: 1, y: 1 }
                     tmpStyles.gradient.end = { x: 0, y: 0 }
                     break
               }
               break
            }
            case 'radial': {
               //TODO Add this for RN
               // const shape = styling.getIn(['background', 'gradient.shape || 'circle';
               // const gradient = `gradient(${strColors})`;
               // styles.background = colors[0];
               // styles.background = `-moz-radial-${gradient}`;
               // styles.background = `-webkit-radial-${gradient}`;
               // styles.background = `radial-${gradient}`;
               // break;
            }
            default:
               console.error('Unknown gradient type:', styling.getIn(['background', 'type']))
               break
         }
         break
      }
      case 'none':
      default:
         break
   }
   return {...accStyles, ...tmpStyles}
}

function textStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {
   if (!styling.has('text')) {
      return accStyles
   }

   type TTextStyle = {
      color?: string
      textAlign?: string
      fontSize: number
      fontFamily?: string
      lineHeight?: number | string
      fontWeight?: number | string
      textShadow?: string
   }
   const tmpStylesText: TTextStyle = {} as TTextStyle
   const tmpStyles: {
      text: TTextStyle
      placeholderColor?: string
   } = {
      text: tmpStylesText   
   }

   if (styling.hasIn(['text', 'color'])) {
      tmpStylesText.color = applyColor(styling.getIn(['text', 'color']), designSystem)
   }
   if (styling.hasIn(['text', 'alignment'])) {
      tmpStylesText.textAlign = styling.getIn(['text', 'alignment']) as string
   }
   if (styling.hasIn(['text', 'size'])) {
   tmpStylesText.fontSize = parseVal(styling.getIn(['text', 'size']) as NumericalValueRecord, designSystem) as number
   
      // if (Platform.OS != 'web') {
      //    let fs = tmpStylesText.fontSize / 3
      //    tmpStylesText.fontSize = fs < 12 ? 12 : fs
      // }
   }
   if (styling.hasIn(['text', 'font'])) {
      const font = styling.getIn(['text', 'font']) as string
      tmpStylesText.fontFamily = font.startsWith('@') ? 'Roboto' : font
   }

   if (styling.hasIn(['text', 'line_height'])) {
      tmpStylesText.lineHeight = parseFloat(styling.getIn(['text', 'line_height']) as string) * tmpStylesText.fontSize
   }

   if (styling.hasIn(['text', 'weight'])) {
      tmpStylesText.fontWeight = styling.getIn(['text', 'weight']) as string
   }

   if (styling.hasIn(['text', 'shadow'])) {
      const color = applyColor(styling.getIn(['text', 'shadow', 'color'], 'black'), designSystem);
      const horizontal = parseVal(styling.getIn(['text', 'shadow', 'horizontal'], NumericalValueRecord()) as NumericalValueRecord, designSystem)
      const vertical = parseVal(styling.getIn(['text', 'shadow', 'vertical'], NumericalValueRecord()) as NumericalValueRecord, styling)
      const blur = parseVal(styling.getIn(['text', 'shadow', 'blur'], NumericalValueRecord()) as NumericalValueRecord, designSystem)
      tmpStylesText.textShadow = `${color} ${horizontal}px ${vertical}px ${blur}px`
   }

   // only for input
   if (styling.getIn(['placeholder', 'color'])) {
      // TODO: fix placeholder text color
      tmpStyles.placeholderColor = applyColor(styling.getIn(['placeholder', 'color']), designSystem)
   }

   return {...accStyles, ...tmpStyles}
}

function iconStyle({
   accStyles,
   styling,
   parentStyle,
   designSystem,
}: {
   accStyles: Record<string, any>
   styling: Map<string, any>
   parentStyle: Map<string, any>
   designSystem: any
}) {
   if (styling.getIn(['icon', 'color'])) {
      if (!accStyles.image) {
         accStyles.image = {}
      }
      accStyles.image.color = applyColor(styling.getIn(['placeholder', 'color']), designSystem)
   }

   return accStyles
}
