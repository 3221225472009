import { connect } from 'react-redux';
import { Map, List } from 'immutable';

import Modal from './ModalComponent';

interface IModalContainerProps {
   parent: Map<string, any>,
   style: any,
   component: Map<string, any>
   nestingAncestry: any
}

function mapStateToProps(state: any, 
   { parent, style, component, nestingAncestry }: IModalContainerProps) {
   const childrenInfo = component.get('children', List()).map((childId: string) => {
      return {
         'uid': childId,
         'position': (
            state.local?.getIn(['atoms', childId, 'computedStyling', 'position', 'type']) ?? 
            state.project?.getIn(['definition', 'atoms', childId, 'custom_styling', 'position', 'type']) ?? 
            'auto'
         ),
         'order': parseInt(
            state.local?.getIn(['atoms', childId, 'computedStyling', 'position', 'auto', 'order']) as string ?? 
            state.project?.getIn(['definition', 'atoms', childId, 'custom_styling', 'position', 'auto', 'order']) as string ?? 
            '0'
         )
      }
   });
   const isEditing = state.project?.getIn(['isEditing']) || false;

   return {
      isEditing,
      component,
      style,
      parent,
      childrenInfo,
      nestingAncestry
   };
}

function mapDispatchToProps(dispatch: any) {
   // add methods here
   return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
