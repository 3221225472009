import React, { ElementType } from 'react'
import { View as RNView, ViewProps, ImageBackground } from 'react-native';
import { Map, List } from 'immutable';

import MetaComponent from '../MetaComponent/MetaContainer';

export interface IChildInfo {
   uid: string,
   position: string
   order: number
}
type IBoxProps = {
   component: Map<string, any>, // ComponentStoreRecord
   style: any, // react css like object
   parent?: Map<string, any>, // ComponentStoreRecord
   childrenInfo: List<IChildInfo>,
   nestingAncestry: any
}
const Box = ({
   component,
   style,
   childrenInfo,
   nestingAncestry
}: IBoxProps) => {

   const backgroundChildren = childrenInfo.filter(
      (childPosition: IChildInfo) => ['absolute', 'relative'].includes(childPosition.position)
   )

   const fixedChildren = childrenInfo.filter(
      (childPosition: IChildInfo) => childPosition.position === 'fixed'
   );

   const regularChildren = childrenInfo.filter(
      (childPosition: IChildInfo) => childPosition.position === 'auto'
   );

   const BoxWrapper = (style?.image?.uri ? ImageBackground : RNView) as ElementType;

   return (
      <BoxWrapper
         resizeMode='cover'
         style={[style.layout, { flex: 1 }]}
         source={{ uri: style?.image?.uri }}
      >
         {/* for fixed positioning */}
         {fixedChildren.size > 0 &&
            fixedChildren
               .sort((a: any, b: any) => (
                  parseInt(a.order as string) - 
                  parseInt(b.order as string)
               ))
               .map((child) => {
                  const suffix = component.getIn(['nestingAncestry', 'suffix']);
                  const uid = !suffix ? child.uid : `${component.get('uid')}_${suffix}`;
                  return (<MetaComponent
                              key={uid}
                              componentId={uid}
                              parent={component}
                              nestingAncestry={nestingAncestry}
                           />)
         })}
         {/* for absolute/relative positioning */}
         {backgroundChildren?.size > 0 && (
            <>
               {backgroundChildren
                  .sort((a: any, b: any) => (
                     parseInt(a.order as string) - 
                     parseInt(b.order as string)
                  ))
                  .map((child) => {
                     const suffix = component.getIn(['nestingAncestry', 'suffix']);
                     const uid = !suffix ? child.uid : `${component.get('uid')}_${suffix}`;
                     return (
                        <MetaComponent
                           key={uid}
                           componentId={uid}
                           parent={component}
                           nestingAncestry={nestingAncestry}
                        />
                     )
               })}
            </>
         )}
         {regularChildren?.size > 0 &&
            regularChildren
               .sort((a: any, b: any) => (
                  parseInt(a.order as string) - 
                  parseInt(b.order as string)
               ))
               .map((child) => {
                  const suffix = component.getIn(['nestingAncestry', 'suffix']);
                  const uid = !suffix ? child.uid : `${component.get('uid')}_${suffix}`;
                  return (<MetaComponent
                              key={uid}
                              componentId={uid}
                              parent={component}
                              nestingAncestry={nestingAncestry}
                           />)
            })}
      </BoxWrapper>
   );
};

export default Box;
