import React, { useEffect } from 'react';
import { ViewProps, View } from 'react-native';
import { List, Map } from 'immutable';

import Loader from '../Loader';
import MetaComponent from '../MetaComponent/MetaContainer';
import { TBox, TCreateComponentFuncAsProp } from '../../types';

// type TProps = TBox & TCreateComponentFuncAsProp & Pick<ViewProps, 'style'>;

export interface IBoxListProps {
   component: Map<string, any>,
   parent: Map<string, any>,
   style: any,
   cellRootId: string,
   listItems: any[],
   nestingAncestry: List<any>,
}
const BoxList = ({
   component,
   parent,
   style,
   cellRootId,
   listItems,
   nestingAncestry,
}: IBoxListProps) => {
   return (
      <View style={style.layout}>
         {listItems.length > 0 ? (
            listItems.map((item, index) => {
               return (
                  <MetaComponent
                     key={index}
                     componentId={cellRootId}
                     parent={component}
                     nestingAncestry={nestingAncestry.push(Map({ 
                        suffix: '_' + index, 
                        rootId: component.get('uid'), 
                        index: index,
                        type: 'list'
                     }))}
                  />
               );
            })
         ) : (
            <Loader />
         )}
      </View>
   );
};

export default BoxList;
