import { Map, List } from 'immutable'

import { setGlobalVar } from '../reducers/GlobalsSlice'
import {
   fetchApi,
   scrollToElement,
   updateComponentData,
   updateComponentProperties,
} from '../reducers/LocalDataSlice'
import { dbCreate, dbDelete, dbRead, dbUpdate, dbUploadFile } from './dbActions'
import { navigateToScreen } from './navigate'
import { parseExpressionAsync } from '../hooks/useInlineLogic'
import { addNewEvent } from '../reducers/EventsSlice'

function findNextActions(workflow: Map<string, any>, currentId: string): Map<string, any>[] {
   if (!workflow.hasIn(['connections', currentId])) {
      return []
   }
   return (
      workflow.getIn(
         ['connections', currentId, 'success'], 
         Map()
      ) as Map<string, any>)
      .keySeq().toArray()
      .map((nodeId: any) => workflow.getIn(['nodes', nodeId], Map())) as Map<string, any>[]
}

const runNode = (
   workflow: Map<string, any>, // IComponentLogicFlow,
   node: Map<string, any>, // ILogicNode,
   data: object,
   componentId: string,
   nestingAncestry: List<any>
) => async (
   dispatch: any, getState: any
) => {
   const tmpData = { ...data }

   switch (node.get('type')) {
      case 'action.scrollToElement': {
         dispatch(scrollToElement(node.getIn(['parameters', 'target'], '') as any))
         break
      }
      case 'action.setComponentData': {
         dispatch(
            updateComponentData({
               uid: node.getIn(['parameters', 'target']),
               key: node.getIn(['parameters', 'data']),
               value: node.getIn(['parameters', 'value']),
            } as any)
         )
         break
      }
      case 'action.setComponentProperty': {
         dispatch(
            updateComponentProperties({
               uid: node.getIn(['parameters', 'uid']),
               key: node.getIn(['parameters', 'target']),
               value: node.getIn(['parameters', 'value']),
            } as any)
         )
         break
      }
      case 'action.setGlobalVar': {
         dispatch(setGlobalVar({ 
            key: node.getIn(['parameters', 'key'], '') as string, 
            value: node.getIn(['parameters', 'value'], '') as string 
         }))
         break
      }
      case 'action.callAPI': {
         const params = Map({
            url: await dispatch(parseExpressionAsync(node.getIn(['parameters', 'url'], '') as string, nestingAncestry)),
            body: await dispatch(parseExpressionAsync(node.getIn(['parameters', 'body'], '') as string, nestingAncestry)),
            headers: await dispatch(parseExpressionAsync(node.getIn(
               ['parameters', 'headers'], 
               "{ 'Content-Type': 'application/json' }"
            ) as string, nestingAncestry)),
            method: node.getIn(['parameters', 'method'], '') || 'GET',
         })
         dispatch(fetchApi(params))
         break
      }
      case 'action.dbCreate': {
         dispatch(dbCreate(node, nestingAncestry))
         break
      }
      case 'action.navigate': {
         dispatch(navigateToScreen(node.getIn(['parameters', 'target'], '') as string, componentId))
         break
      }
      case 'action.dbUpdate': {
         dispatch(dbUpdate(node, nestingAncestry))
         break
      }
      case 'action.dbDelete': {
         dispatch(dbDelete(node, nestingAncestry))
         break
      }
      case 'action.dbUploadFile': {
         dispatch(dbUploadFile(node))
         break
      }
      case 'trigger.Event': {
         dispatch(addNewEvent(node.getIn(['parameters', 'target'])))
         break
      }
      default:
         break
   }

   const nextNodes = findNextActions(workflow, node.get('uid'))
   if (nextNodes.length === 1 && nextNodes[0]) {
      dispatch(runNode(workflow, nextNodes[0], tmpData, componentId, nestingAncestry))
   }
}

// async action
export const runWorkflow = (
   componentId: string, workflowId: string, nestingAncestry: List<any>
) => async (
   dispatch: any, getState: any
) => {
   try {
      const workflow = getState().project.getIn(['definition', 'atoms', componentId, 'logic', workflowId]) as Map<string, any>
      const triggerNode = workflow
         .get('nodes')
         .find(
            (node: Map<string, any>, nodeId: string) => node.get('type', '').startsWith('trigger.')
         ) // ILogicNode

      if (!triggerNode) return;

      dispatch(runNode(workflow, triggerNode, {}, componentId, nestingAncestry))
   } catch (error) {
      console.error(`Error running workflow \n(componentId: ${componentId}, workflowId: ${workflowId})`, error)
   }
}


export function getWorkflows(
   componentLogic: Map<string, any>, 
   type: string
): Map<string, any>{
   return (componentLogic ?? Map()).filter(
      (logicWorkflow: Map<string, any>) => {
         return logicWorkflow.get('nodes', Map()).filter(
            (node: Map<string, any>) => node?.get('type', '') === type
         ).size > 0
   })
}