import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';

import Box, { IChildInfo } from './BoxComponent';
import { TState } from '../../reducers';

interface IBoxContainerProps {
   parent: Map<string, any>,
   style: any,
   component: Map<string, any>
   nestingAncestry: any
}
function mapStateToProps(
   state: TState,
   { parent, style, component, nestingAncestry }: IBoxContainerProps
) : any {
   const childrenInfo = component.get('children', List()).map((childId: string) => {
      return {
         'uid': childId,
         'position': (
            state.local?.getIn(['atoms', childId, 'computedStyling', 'position', 'type']) ?? 
            state.project?.getIn(['definition', 'atoms', childId, 'custom_styling', 'position', 'type']) ?? 
            'auto'
         ),
         'order': parseInt(
            state.local?.getIn(['atoms', childId, 'computedStyling', 'position', 'auto', 'order']) as string ?? 
            state.project?.getIn(['definition', 'atoms', childId, 'custom_styling', 'position', 'auto', 'order']) as string ?? 
            '0'
         )
      }
   });

   return {
      component,
      style,
      parent,
      childrenInfo,
      nestingAncestry
   };
}

export default connect(mapStateToProps, null)(Box);
