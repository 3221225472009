import { createSlice } from '@reduxjs/toolkit';
import { fromJS, Map, Record, RecordOf } from 'immutable'
import { realtimeDB } from '../lib/firebase';
import {  } from 'immutable'

export const fetchModel = (projectId:string, envId: string): Promise<string> => {
   const env = envId || 'prod';
   return realtimeDB.readData(`projects/${projectId}/${env}`)
};

export type TProjectSlice = {
   projectId: string
   definition: Map<string, any>
   loaded: boolean
}
const defaultSliceState: TProjectSlice = {
   projectId: '',
   definition: Map(),
   loaded: false,
}
const ProjectSliceRecord: Record.Factory<TProjectSlice> = Record(defaultSliceState)
export type ProjectSliceRecord = RecordOf<TProjectSlice>
const projectSlice = createSlice({
   name: 'project',
   initialState: ProjectSliceRecord(),
   reducers: {
      setProjectId: (state: any, action: { payload: string }) => {
         return state.set('projectId', action.payload)
      },
      setProjectDefinition: (state: any, action: { payload: Map<string, any> }) => {
         return state.set('definition', action.payload);
      },
      setProjectLoaded: (state: any, action: { payload: boolean }) => {
         return state.set('loaded', action.payload);
      },
   },
});

export const ProjectReducer = projectSlice.reducer;
export const { setProjectDefinition, setProjectLoaded, setProjectId } = projectSlice.actions;
