import { createSlice } from '@reduxjs/toolkit'
import { fromJS, Map, List, Record, RecordOf } from 'immutable'

import { TSchemaComponent, TComponentType } from '../types'
import { getWorkflows } from '../lib'


type defaultTypes = 'string' | 'number' | 'boolean' | 'list' | 'map'
const defaultTypeValues = {
   'string': '',
   'number': 0,
   'boolean': false,
   'list': [],
   'map': {},
}

// Component
export type TComponentStore = TSchemaComponent & {
   uid: string

   // unnecessary but convenient for now
   schemaUid: string
   type: TComponentType
   children: List<string>
   // logic: Map<string, any>
   // visual_logic: Map<string, any>

   // required by the container
   computedStyle: Map<string, any>
   computedProperties: Map<string, any>
   computedData: Map<string, any>
   
   // used by hooks to keep track of changes
   logicConditions: List<boolean>
   visualConditions: List<boolean>
}
const defaultComponentStoreValues: TComponentStore = {
   uid: '',

   computedStyle: Map(),
   computedProperties: Map(),
   computedData: Map(),
   logicConditions: List(),
   visualConditions: List(),

   // unnecessary but convenient for now
   schemaUid: '',
   type: 'box',
   children: List(),
   // logic: Map(),
   // visual_logic: Map(),
}
export const ComponentStoreRecord: Record.Factory<TComponentStore> = Record(defaultComponentStoreValues)
export type ComponentStoreRecord = RecordOf<TComponentStore>


type fetchApiParams = {
   method: 'GET' | 'POST' | 'PUT' | 'DELETE'
   url: string
   headers?: string
   body?: string
}
// const defaultFetchAPIParamsValues: fetchApiParams = {
//    method: 'GET',
//    url: '',
//    headers: '{}',
//    body: '{}',
// }
// const FetchAPIparamsRecord: Record.Factory<fetchApiParams> = 
//    Record(defaultFetchAPIParamsValues)
// type FetchAPIparamsRecord = RecordOf<fetchApiParams>
export const fetchApi = (params: Map<string, any>) => async (dispatch: any, getState: any) => {
   const payload = {
      method: params.get('method'),
      headers: JSON.parse(params.get('headers') as string),
      body: params.get('body')
   }
   return await fetch(params.get('url'), payload).then((response) => response.json())
}


export type TLocalDataSlice = {
   loaded: boolean
   atoms: Map<string, ComponentStoreRecord>
   screens: Map<string, any>
   scrollPosition: {
      x: number
      y: number
   }
   // globals: Map<string, > // Should be in globals?
   navigation?: Map<string, any>
   scrollRoot: any
   cache: Map<number, any>
}
const defaultSliceState: TLocalDataSlice = {
   loaded: false,
   atoms: Map(),
   screens: Map(),
   scrollPosition: { x: 0, y: 0 },
   // globals: Map<string, > // Should be in globals?
   navigation: Map(),
   scrollRoot: null,
   cache: Map(),
}
const LocalDataSliceRecord: Record.Factory<TLocalDataSlice> = Record(defaultSliceState)
export type LocalDataSliceRecord = RecordOf<TLocalDataSlice>
const localDataSlice = createSlice({
   name: 'local',
   initialState: LocalDataSliceRecord(),
   reducers: {
      setScreen: (state: any, action: any) => {
         const { uid, screen, navigation }: { uid: string; screen: any; navigation: any } = action.payload;
         const storeScreen: Map<string, any> = Map({ 
            ...screen,
            navigation
         })

         return state.setIn(['screens', uid], storeScreen)
      },
      initializeComponent: (
         state: any, 
         { payload }: { payload: {
            component: Map<string, any>, // ComponentRecord,
            nestingAncestry: List<any>,
            style: Map<string, any> // ComponentStylingRecord
         } }
      ) => {
         // Initialize a component from schema
         // Should not replace existing component in case the component has already been mounted
         // and some local data must be persisted
         const { component, nestingAncestry, style } = payload
         if (state.hasIn(['atoms', component.get('uid')])) return state

         const storeComponent: Map<string, any> = Map({ 
            uid: component.get('uid') + nestingAncestry.map((cell: Map<string, any>) => cell.get('suffix')).join(''),
            schemaUid: component.get('uid'),
            type: component.get('type'),
            children: component.get('children', List()),
            computedProperties: component.get('properties', Map()),
            computedStyle: component.get('custom_styling', Map())?.mergeDeep(style),
            computedData: (
               component.get('data', Map()) as Map<string, any>
            ).map((data: Map<string, any>) => data.get(
               'default', 
               defaultTypeValues[data.get('type', 'string') as defaultTypes]
            ) ?? defaultTypeValues[data.get('type', 'string') as defaultTypes]),
            logicConditions: getWorkflows(
               component.get('logic', Map()) as Map<string, any>, 
               'trigger.condition'
            ).map(() => false),
            visualConditions: component.get('visual_logic', List()).map(() => false),
         })

         return state.setIn(['atoms', storeComponent.get('uid')], storeComponent)
      },
      updateCache: (state: any, { payload }: { payload: { key: number; value: any } }) => {
         const { key, value } = payload
         return state.setIn(['cache', key], value)
      },
      updateLocalStorage: (state: any, action: any) => {
         const { path, value }: { path: string[], value: any } = action.payload
         return state.setIn(path, value)
      },
      refreshComponentStyle: (
         state: any, 
         action: { payload: {
            componentId: string
            passiveStyle: Map<string, any>
            conditionalStyle: List<Map<string, any>>
            passiveProperties: Map<string, any>
            conditionalProperties: List<Map<string, any>>
         } }
      ) => {
         return state.setIn(
            ['atoms', action.payload.componentId, 'computedStyle'],
            action.payload.conditionalStyle.reduce(
               (acc: Map<string, any>, style: Map<string, any>) => acc.mergeDeep(style),
               action.payload.passiveStyle
            )
         ).setIn(
            ['atoms', action.payload.componentId, 'computedProperties'],
            action.payload.conditionalProperties.reduce(
               (acc: Map<string, any>, properties: Map<string, any>) => acc.mergeDeep(properties),
               action.payload.passiveProperties
            )
         )
      },

      setComponent: (state: any, action: any) => {
         const {
            uid,
            component,
            parentId,
         }: { uid: string; component: ComponentStoreRecord; parentId: string } = action.payload
         return state.setIn(['atoms', uid], component)
      },
      // updateComponent: (state: any, action: any) => {
      //    const { uid, component }: { uid: string; component: ComponentStoreRecord } = action.payload
      //    return state.mergeDeepIn(['atoms', uid], component)
      // },
      // updateComponentVisualConditions: (state: any, action: any) => {
      //    const { uid, conditions }: { uid: string; conditions: boolean[] } = action.payload
      //    return state.mergeDeepIn(['atoms', uid, 'visualConditions'], conditions)
      // },
      // // Should be a thunk action
      // updateComponentConditions: (state: any, action: any) => {
      //    const { uid, conditions }: { uid: string; conditions: Map<string, boolean> } =
      //       action.payload
      //    const workflowsToTrigger = conditions.filter((condition: boolean, workflowId: string) => (
      //       condition &&
      //       condition !== state.getIn(['atoms', uid, 'conditions', workflowId])
      //    ))
      //    // dispatch workflows
      //    return state.mergeDeepIn(['atoms', uid], conditions)
      // },
      // completeComponentWorkflow: (state: any, action: any) => {
      //    const { uid, workflowId }: { uid: string; workflowId: string } = action.payload
      //    state.atoms[uid].pendingWorkflowIds = state.atoms[uid]?.pendingWorkflowIds?.filter(
      //       (id) => id !== workflowId
      //    )
      // },
      updateComponentLayout: (state: any, action) => {
         const { componentId, layout }: { componentId: string; layout: any } = action.payload
         return state.mergeDeepIn(['atoms', componentId, 'layout'], layout)
      },
      updateAllComponentProperties: (state: any, action: any) => {
         const { uid, props }: { uid: string; props: Map<string, any> } = action.payload
         return state.setIn(['atoms', uid, 'properties'], props)
      },
      updateComponentProperties: (state: any, action: any) : any => {
         const { uid, key, value }: { uid: string; key: string; value: any } = action.payload
         return state.setIn(['atoms', uid, 'computedProperties', key], value)
      },
      updateComponentData: (state: any, action: any): any => {
         const { uid, key, value }: { uid: string; key: string; value: any } = action.payload
         return state.setIn(['atoms', uid, 'data', key], value)
      },
      initComponentStyling: (state: any, action: any): any => {
         const { uid, value }: { uid: string; value: Map<string, any> } = action.payload
         return state.setIn(['atoms', uid, 'computedStyle'], value)
      },
      updateComponentStyling: (state: any, action: any): any => {
         const { uid, value }: { uid: string; value: Map<string, any> } = action.payload
         return state.setIn(['atoms', uid, 'computedStyle'], value)
      },
      componentsLoaded: (state: any) => {
         return state.set('loaded', true)
      },
      // Used for scroll events
      addScrollComponent: (state: any, action: any) => {
         return state.set('scrollRoot', action.payload)
      },
      scrollToElement: (state: any, action: any) => {
         state.get('scrollRoot').scrollTo({
            x: 0, //state.getIn(['atoms', action.payload, 'layout', 'x']),
            y: state.getIn(['atoms', action.payload, 'layout', 'y']),
            animated: true
         })
         return state
      },

      updateScreenLayout: (state: any, action) => {
         const { width, height, x }: { width: number; height: number; x?: number; y?: number } =
            action.payload
         return state.setIn(
            ['screen'],
            Map({
               width,
               height,
               x,
               view: width > 1440 ? 'xl' : width > 992 ? 'web' : width > 768 ? 'tablet' : 'mobile',
            })
         )
      },
   },
})

export const LocalDataReducer = localDataSlice.reducer;
export const {
   setScreen,
   // initComponents,
   initializeComponent,
   updateLocalStorage,
   updateCache,
   refreshComponentStyle,

   setComponent,
   // updateComponent,
   initComponentStyling,
   // updateComponentStyling,
   // updateComponentVisualConditions,
   // updateComponentConditions,
   updateComponentLayout,
   updateComponentProperties,
   updateAllComponentProperties,
   updateComponentData,
   addScrollComponent,
   scrollToElement,
   componentsLoaded,
   updateScreenLayout,
} = localDataSlice.actions
