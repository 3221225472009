import { View, Text } from "react-native";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Map, List, fromJS } from "immutable";

import { executeInlineNode, resolveInlineLogic, regex } from "../../hooks/useInlineLogic";
import { updateLocalStorage } from "../../reducers/LocalDataSlice";

const immutableEqualityCheck = (a: any, b: any) => {
   return a.hashCode() === b.hashCode();
}

export default function InlineLogic({
   expression,
   localStoragePath,
   nestingAncestry
}: {
   expression: string | Map<string, any>,
   localStoragePath: string[],
   nestingAncestry: List<any>,
}) {
   const dispatch = useDispatch();

   const localValue = useSelector((state: any) => state.local.getIn(localStoragePath))

   const [logicExpressions, setLogicExpressions]: [
      List<Map<string, any>>,
      (value: List<Map<string, any>>) => void
   ] = useState(List())

   useEffect(
      () => {
         if (typeof expression === 'string') {
            const matches = List(expression.match(regex)?.slice(1) as string[])
            setLogicExpressions(matches.map(match => fromJS(JSON.parse(match))) as List<Map<string, any>>)
         } else if (Map.isMap(expression)) {
            setLogicExpressions(List([expression]))
         }
      },
      [expression]
   )

   const inputs = useSelector(
      (state: Record<string, any>) => {
         const entryPoints = logicExpressions.reduce(
            (acc: List<any>, logic: Map<string, any>) => {
               try {
                  const localEntryPoints = logic.get('nodes', Map())
                     .filter((node: Map<string, any>) => node.get('type').startsWith('inline-entry.'))
                     .map((node: Map<string, any>) => {
                        const nodeRes = dispatch(executeInlineNode(node, [], nestingAncestry) as any)
                        return nodeRes
                     })
                  return acc.concat(localEntryPoints)
               } catch (e) {
                  console.error('Error parsing inline logic', logic.toJS(), e)
                  return acc
               }
            },
            List()
         ) as List<any>
         return entryPoints
      },
      immutableEqualityCheck
   );

   useEffect(
      () => {
         logicExpressions.forEach(logic => {
            const resolvedValue = dispatch(resolveInlineLogic(logic, nestingAncestry) as any)
            if (resolvedValue !== undefined) {
               dispatch(updateLocalStorage({ path: localStoragePath, value: resolvedValue } as any));
            }
         })
      },
      [inputs]
   )

   return null
}
