import React, { useEffect, useState } from 'react';
import { TextInputProps } from 'react-native';
import { useDispatch } from 'react-redux';
import { TInput, TCreateComponentFuncAsProp } from '../../types';

import TextInput from './TextInput';
import ImagePicker from './ImagePicker';
import DocumentPicker from './DocumentPicker';
import { ComponentStoreRecord } from '../../reducers';

// type TProps = TInput & TCreateComponentFuncAsProp & Pick<TextInputProps, 'style'>;

type IBoxProps = {
   component: ComponentStoreRecord,
   parent?: ComponentStoreRecord,
   nestingAncestry: any,
   handleTextChanges: any
}

const Input = ({
   component,
   parent,
   nestingAncestry,
   handleTextChanges
}: IBoxProps) => {
   const dispatch = useDispatch();

   const type = component.getIn(['properties', 'input', 'type']);
   const input_value = component.getIn(['data', 'input_value']);
   const uid = component.get('uid');

   const [inputType, setInputType] = useState(type || 'text');

   useEffect(() => {
      setInputType(type || '');
   }, [type]);

   if (inputType === 'document') {
      return (
         <DocumentPicker
            fileType={'csv'}
            // onFileChange={(_: any) => console.error('Error: expecting "onFileChange" function')}
            fileURI={input_value}
            uid={uid}
         />
      );
   }
   if (inputType === 'image') {
      return <ImagePicker uid={uid} imageURI={input_value} />;
   }
   return (
      <TextInput
         component={component}
         // style={style}
         parent={parent}
         nestingAncestry={nestingAncestry}
         handleTextChanges={handleTextChanges}
      />
   );
};

export default Input;
