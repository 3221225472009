import { useState, useEffect } from 'react'
import Constants from 'expo-constants';
import { Linking } from 'react-native';
import { fromJS, Map } from 'immutable'

import { Engine } from './../src'

import ___schema from './../assets/NewStructure/GenCom.json'
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { AUTH_USER_URL, PROJECTS_URL } from './Urls';

export const Root = () => {
   const [initializingProjectId, setInitializingProjectId] = useState(true)
   const [schema, setSchema] = useState(Map() as Map<string, any>)
   const [projectId, setProjectId] = useState('')

   const fetchProjectStructure = async () => {
      // get projectId
      const url: any = await Linking.getInitialURL();
      var regex = /[?&]([^=#]+)=([^&#]*)/g,
         params: any = {},
         match;
      while ((match = regex.exec(url))) {
         params[match[1]] = match[2];
      }
      const projectId = params['projectId'] || Constants?.expoConfig?.extra?.projectId || 'da4ba167-caa1-4284-a63d-50b0dcf489c9';

      axios({
         method: 'POST',
         url: `${AUTH_USER_URL}`,
         data: {
            email: "pavelgrachev22@gmail.com",
            password: "123456"
         }
       }).then((authResponse: any) => {
         axios({
            method: 'GET',
            url: `${PROJECTS_URL}/${projectId}/`,
            headers: {
               Accept: 'application/json',
               Authorization: `Bearer ${authResponse.data.token}`,
            }
          }).then((response: any) => {
            setProjectId(projectId)
            setSchema(fromJS(response.data))
            setInitializingProjectId(false)
          }).catch((err) => {
            console.log(err)
          });
       });
   }

   useEffect(() => {
      if (initializingProjectId) {
         fetchProjectStructure()
      }
   }, [])

   if (initializingProjectId) return null

   return <Engine schema={schema} projectId={projectId} />
}
