import { Map, List, fromJS } from 'immutable'

import { IInlineLogic, IInlineLogicNode, IInlineLogicGet } from '../types/schema/logic'
import { updateComponentProperties } from '../reducers/LocalDataSlice'
import { InlineEntries, InlineNodes } from './conditionLogic/index'
import { getWorkflows } from '../lib/logicWorkflow'


export const regex = /@@([\w\W]*)@@/

export const parseExpressionAsync = (
   expression: Map<string, any> | string, // IInlineLogic,
   nestingAncestry: List<string>
) => async (
   dispatch: any, 
   getState: any
) => {
   // find if there are any inline logic expressions in the expression
   let logicExpressions: Map<string, any>[] = []
   if (typeof expression === 'string') {
      const matches = expression.match(regex)?.slice(1) as string[]

      if(matches)
         logicExpressions = matches.map(match => fromJS(JSON.parse(match))) as Map<string, any>[]
      else
         return expression;
   } else if (Map.isMap(expression)) {
      logicExpressions = [expression]
   }

   // resolve the inline logic expressions
   const resolvedValues = await Promise.all(logicExpressions.map(
      (logic: Map<string, any>) => dispatch(resolveInlineLogicAsync(logic, nestingAncestry))
   ))
   // replace in the expression
   if (typeof expression === 'string') {
      let result = expression
      logicExpressions.forEach((logic, index) => {
         result = result.replace(`@@${JSON.stringify(logic.toJS())}@@`, resolvedValues[index])
      })
      return result
   }
   return resolvedValues[0]
}

export const resolveInlineLogicAsync = (
   expression: Map<string, any>, // IInlineLogic,
   nestingAncestry: List<string>
) => async (
   dispatch: any, 
   getState: any
) => {
   const entryPoints: List<Map<string, any>> = expression.get('nodes', Map())
      .filter((node: Map<string, any>) => node.get('type').startsWith('inline-entry.'))
      .toList()

   const executionStack = entryPoints.toArray() // IInlineLogicNode<any>[]

   const scheduleNext = (nodeId: string) => {
      try {
         if (expression.hasIn(['connections', nodeId])) {
            const keysOfNextNodes = (expression.getIn(['connections', nodeId, 'success'], Map()) as Map<string, any>).keySeq()
            // const keysOfNextNodes = Object.keys(connections[nodeId].success)
            keysOfNextNodes.forEach((key) => {
               executionStack.push(expression.getIn(['nodes', key], Map()) as Map<string, any>)
            })
         }
      } catch (ex) {
         console.error('Error scheduling next inline action', ex)
      }
   }

   try {
      const data: Record<string, any> = {}
      let result
      while (executionStack.length > 0) {
         const node = executionStack.shift() as Map<string, any> // as IInlineLogicNode<any>
         const inboundNodes = expression.get('connections', Map()).keySeq().filter((connectionId: string) =>
            (expression.getIn(['connections', connectionId, 'success'], Map()) as Map<string, any>).keySeq().some((connection: any) => {
               return node?.get('uid') === connection
            })
         )
         const nodeParams = inboundNodes
            .filter((nodeId: string) => nodeId in data)
            .map((nodeId: string) => data[nodeId])
         if (nodeParams.length !== inboundNodes.length) {
            executionStack.push(node)
            continue
         }
         const res = dispatch(executeInlineNode(node, nodeParams, nestingAncestry))
         if (expression.hasIn(['connections', node.get('uid')])) {
            scheduleNext(node.get('uid'))
            data[node.get('uid')] = res
         } else {
            result = res
         }
      }
      return result
   } catch (ex) {
      console.error('Error', ex)
      return false
   }
}

export const parseExpression = (
   expression: Map<string, any> | string, // IInlineLogic,
   nestingAncestry: List<string>
) => (
   dispatch: any, 
   getState: any
) => {
   // find if there are any inline logic expressions in the expression
   let logicExpressions: List<Map<string, any>> = List()
   if (typeof expression === 'string') {
      const matches = List(expression.match(regex)?.slice(1) as string[])
      if(matches)
         logicExpressions = matches.map((match: any) => fromJS(JSON.parse(match))) as List<Map<string, any>>
      else
         return expression;
   } else if (Map.isMap(expression)) {
      logicExpressions = List([expression])
   }

   // resolve the inline logic expressions
   const resolvedValues = logicExpressions.map(
      (logic: Map<string, any>) => dispatch(resolveInlineLogic(logic, nestingAncestry))
   )
   // replace in the expression
   if (typeof expression === 'string') {
      let result = expression
      logicExpressions.forEach((logic: any, index: any) => {
         result = result.replace(`@@${JSON.stringify(logic.toJS())}@@`, resolvedValues.get(index))
      })
      return result
   }
   return resolvedValues.first()
}

export const resolveInlineLogic = (
   expression: Map<string, any>, // IInlineLogic,
   nestingAncestry: List<string>
) => (
   dispatch: any, 
   getState: any
) => {
   const entryPoints: List<Map<string, any>> = expression.get('nodes', Map())
      .filter((node: Map<string, any>) => node.get('type').startsWith('inline-entry.'))
      .toList()

   const executionStack = entryPoints.toArray() // IInlineLogicNode<any>[]

   const scheduleNext = (nodeId: string) => {
      try {
         if (expression.hasIn(['connections', nodeId])) {
            const keysOfNextNodes = (expression.getIn(['connections', nodeId, 'success'], Map()) as Map<string, any>).keySeq()
            // const keysOfNextNodes = Object.keys(connections[nodeId].success)
            keysOfNextNodes.forEach((key) => {
               executionStack.push(expression.getIn(['nodes', key], Map()) as Map<string, any>)
            })
         }
      } catch (ex) {
         console.error('Error scheduling next inline action', ex)
      }
   }

   try {
      const data: Record<string, any> = {}
      let result
      while (executionStack.length > 0) {
         const node = executionStack.shift() as Map<string, any> // as IInlineLogicNode<any>
         const inboundNodes = expression.get('connections', Map()).keySeq().filter((connectionId: string) =>
            (expression.getIn(['connections', connectionId, 'success'], Map()) as Map<string, any>).keySeq().some((connection: any) => {
               return node?.get('uid') === connection
            })
         )
         const nodeParams = inboundNodes
            .filter((nodeId: string) => nodeId in data)
            .map((nodeId: string) => data[nodeId])
         if (nodeParams.length !== inboundNodes.length) {
            executionStack.push(node)
            continue
         }
         const res = dispatch(executeInlineNode(node, nodeParams, nestingAncestry))
         if (expression.hasIn(['connections', node.get('uid')])) {
            scheduleNext(node.get('uid'))
            data[node.get('uid')] = res
         } else {
            result = res
         }
      }
      return result
   } catch (ex) {
      console.error('Error', ex)
      return false
   }
}

export const executeInlineNode = (
   node: Map<string, any>, // IInlineLogicNode<any>,
   params: any[],
   nestingAncestry: List<string>
) => (dispatch: any, getState: any) => {
   if (!node.has('type')) {
      console.warn('Node type not defined', node)
      return false
   }

   switch (node.get('type')) {
      // TODO: implement all inline entries
      case 'inline-entry.dbRead':
         return dispatch(InlineEntries.ReadDB(node))
      case 'inline-entry.GetGlobalVar':
         return getState().globals.get(node.getIn(['parameters', 'target']));
      case 'inline-entry.GetComponent':
         return node.getIn(['parameters', 'target'])
      case 'inline-entry.Litteral':
         return node.getIn(['parameters', 'value']);
      case 'inline-entry.CurrentUser':
         return dispatch(InlineEntries.CurrentUser(params, node))
      case 'inline-entry.GetScreenData':
         return dispatch(InlineEntries.GetScreenData(node))
      case 'inline-entry.CallAPI': {
         return dispatch(InlineEntries.CallAPI(node))
      }
      case 'inline-entry.GetCurrentCellItem':
         return dispatch(InlineEntries.GetCurrentCellItem(nestingAncestry, node))
      // TODO: implement all inline transformation nodes
      case 'inline-node.GetAttribute':
         return dispatch(InlineNodes.GetAttribute(params, node))
      case 'inline-node.GetProperty': {
         return dispatch(InlineNodes.GetProperty(params, node))
      }
      case 'inline-node.GetComponentData': {
         return dispatch(InlineNodes.GetComponentData(params, node))
      }
      case 'inline-node.Comparison': {
         return InlineNodes.Comparison(params, node)
      }
      case 'inline-node.GetLastItem': {
         return InlineNodes.GetLastItem(params)
      }
      case 'inline-node.Contains': {
         return InlineNodes.Contains(params, node)
      }
      default:
         console.warn('Unknown node type', node.get('type'))
         return false
   }
}


export const executeInlineNodeAsync = (
   node: Map<string, any>, // IInlineLogicNode<any>,
   params: any[],
   nestingAncestry: List<string>
) => async (dispatch: any, getState: any) => {
   if (!node.has('type')) {
      console.warn('Node type not defined', node)
      return false
   }

   switch (node.get('type')) {
      // TODO: implement all inline entries
      case 'inline-entry.dbRead':
         return dispatch(InlineEntries.ReadDBAsync(node))
      case 'inline-entry.GetGlobalVar':
         return getState().globals.get(node.getIn(['parameters', 'target']));
      case 'inline-entry.GetComponent':
         return node.getIn(['parameters', 'target'])
      case 'inline-entry.Litteral':
         return node.getIn(['parameters', 'value']);
      case 'inline-entry.CurrentUser':
         return dispatch(InlineEntries.CurrentUser(params, node))
      case 'inline-entry.GetScreenData':
         return dispatch(InlineEntries.GetScreenData(node))
      case 'inline-entry.CallAPI': {
         return dispatch(InlineEntries.CallAPI(node))
      }
      case 'inline-entry.GetCurrentCellItem':
         return dispatch(InlineEntries.GetCurrentCellItem(nestingAncestry, node))
      // TODO: implement all inline transformation nodes
      case 'inline-node.GetAttribute':
         return dispatch(InlineNodes.GetAttribute(params, node))
      case 'inline-node.GetProperty': {
         return dispatch(InlineNodes.GetProperty(params, node))
      }
      case 'inline-node.GetComponentData': {
         return dispatch(InlineNodes.GetComponentData(params, node))
      }
      case 'inline-node.Comparison': {
         return InlineNodes.Comparison(params, node)
      }
      case 'inline-node.GetLastItem': {
         return InlineNodes.GetLastItem(params)
      }
      case 'inline-node.Contains': {
         return InlineNodes.Contains(params, node)
      }
      default:
         console.warn('Unknown node type', node.get('type'))
         return false
   }
}