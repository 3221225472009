import { Map, List } from 'immutable';

import { dbRead, dbReadAsync, dbWatch } from '../../lib/dbActions';
import  { IInlineLogicGet, IInlineLogicNode, IInlineLogicCallAPI } from '../../types'

const ReadDB = (node: Map<string, any>) => ( // node: IInlineLogicNode<any>
   dispatch: any,
   getState: any
) => {
   // TODO: hashCode is not a guarantee of uniqueness
   const data = getState().local.getIn(['cache', node.get('parameters').hashCode()]);
   if (data === undefined) {
      if (node.getIn(['parameters', 'snapshot'], false) === true) {
         dispatch(dbRead(node.get('parameters')))
      } else {
         dispatch(dbWatch(node.get('parameters')))
      }
   }
   
   return data;
}

const ReadDBAsync = (node: Map<string, any>) => ( // node: IInlineLogicNode<any>
   dispatch: any,
   getState: any
) => {
   return dispatch(dbReadAsync(node.get('parameters')));
}

const CurrentUser = (params: any[], node: Map<string, any>) => (
   dispatch: any,
   getState: any,
) => {
   console.warn('CurrentUser not implemented yet');
   return false;
}

const GetScreenData = (node: Map<string, any>) => (
   dispatch: any,
   getState: any,
) => {
   switch (node.getIn(['parameters', 'target'])) {
      case 'Current Screen Width':
         return getState().local.getIn(['screen', 'width']);
      default:
         return false;
   }
}

const CallAPI = (node: Map<string, any>) => ( // IInlineLogicNode<IInlineLogicCallAPI>
   dispatch: any,
   getState: any
) => {
   return {
      url: node.getIn(['parameters', 'path']),
      params: {
         body: node.getIn(['parameters', 'body'], undefined),
         headers: node.getIn(['parameters', 'headers'], { 'Content-Type': 'application/json' }),
         method: node.getIn(['parameters', 'method'], 'GET'),
      },
   };
}

const GetCurrentCellItem = (nestingAncestry: List<any>, node: Map<string, any>) => (
   dispatch: any,
   getState: any
) => {
   const path = [
      'atoms', 
      nestingAncestry.last()?.get('rootId'), 
      'computedProperties', 
      'list_of_items', 
      nestingAncestry.last().get('index')
   ]
   return getState().local?.getIn(path, '')[node.getIn(['parameters', 'target'], 'uid') as string];
}

export default { 
   ReadDBAsync,
   ReadDB,
   CurrentUser, 
   GetScreenData, 
   CallAPI, 
   GetCurrentCellItem 
}
