export const chartConfig = {
   backgroundColor: '#e26a00',
   backgroundGradientFrom: '#fb8c00',
   backgroundGradientTo: '#ffa726',
   decimalPlaces: 2, // optional, defaults to 2dp
   color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
   labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
   style: {
      borderRadius: 16,
   },
   propsForDots: {
      r: '6',
      strokeWidth: '2',
      stroke: '#ffa726',
   },
};
