import { Tconstraint } from '../types'
import { getAnalytics } from 'firebase/analytics'
import { initializeApp, getApps, getApp} from 'firebase/app'
import {
   getAuth,
   signInWithEmailAndPassword,
   createUserWithEmailAndPassword,
   signOut,
   sendPasswordResetEmail,
   confirmPasswordReset,
   updateProfile,
   User,
} from 'firebase/auth'
import { getDatabase, ref, child, get, set, push, update, remove, onValue } from 'firebase/database'
import { getFirestore, setDoc, getDoc, updateDoc, deleteField, doc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
   getStorage,
   deleteObject,
   ref as sref,
   listAll,
   uploadBytes,
   getDownloadURL,
} from 'firebase/storage'
import { useStore } from 'react-redux'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//    apiKey: "AIzaSyDx6poEvoN5PYxb-j_VI28xM3IrOOimBWs",
//    authDomain: "budhapp-5ef13.firebaseapp.com",
//    databaseURL: "https://budhapp-5ef13-default-rtdb.europe-west1.firebasedatabase.app",
//    projectId: "budhapp-5ef13",
//    storageBucket: "budhapp-5ef13.appspot.com",
//    messagingSenderId: "402827431122",
//    appId: "1:402827431122:web:8c57654436ab940d23f10b",
//    measurementId: "G-QVKSCPBTYW"
// }

const firebaseConfig = {
  apiKey: "AIzaSyC2cbk0-KH5KhLhVOH_hyExhCwzPI5iG6U",
  authDomain: "budhapp-c31ca.firebaseapp.com",
  databaseURL: "https://budhapp-c31ca-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "budhapp-c31ca",
  storageBucket: "budhapp-c31ca.appspot.com",
  messagingSenderId: "501633314523",
  appId: "1:501633314523:web:b8fcfd2838dc06c5e566fe",
  measurementId: "G-WV3MJMNCN2"
}

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const analytics = getAnalytics(app)
// Initialize Cloud Firestore and get a reference to the service
const _firestoreDB = getFirestore(app)

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app)
const login = async (email: string, password: string) =>
   signInWithEmailAndPassword(auth, email, password)
const register = async (email: string, password: string) =>
   createUserWithEmailAndPassword(auth, email, password)
const logout = async () => signOut(auth)
const resetPassword = async (securityCode: string, newPassword: string) =>
   confirmPasswordReset(auth, securityCode, newPassword)
const sendPasswordResetCode = async (email: string, callbackUrl: string) => {
   const actionCodeSettings = {
      url: callbackUrl,
      // url: 'https://www.example.com/?email=user@example.com', // custom url
      iOS: {
         bundleId: 'com.example.ios',
      },
      android: {
         packageName: 'com.example.android',
         installApp: true,
         minimumVersion: '12',
      },
      handleCodeInApp: true,
   }
   return sendPasswordResetEmail(auth, email, actionCodeSettings)
}
const updateUserProfile = async (user: User, payload: any) => updateProfile(user, payload)
export const firebaseAuth = {
   login,
   register,
   logout,
   sendPasswordResetCode,
   resetPassword,
   updateUserProfile,
}

// Realtime DB
const _realtimeDB = getDatabase(app)
const readData = async (path: string) => {
   const snapshot = await get(child(ref(_realtimeDB), path))
   if (snapshot.exists()) {
      return snapshot.val()
   }
   return null
}

const createRealtimeDB = async (path: string, data: Record<string, any>) => {
   return set(ref(_realtimeDB, path), data).then(() => {
      return data
   })
}
const readRealtimeDB = async (
   path: string,
   assetType: string,
   constraints: Tconstraint,
   view: Record<string, string>
) => {
   // TODO: Add constraints
   // import store from './../store';
   // let projectId = store.getState().project.projectId;
   // let projectId = getState.project.projectId;

   // const { dispatch, getState, subscribe } = useStore();
   const snapshot = await get(child(ref(_realtimeDB), path))
   if (snapshot.exists()) {
      return snapshot.val()
   }
   return null
}
const watchRealtimeDB = (
   path: string,
   callback: (data: any) => void,
   assetType: string,
   constraints: Tconstraint,
   view: Record<string, string>
) => {
   onValue(ref(_realtimeDB, path), (snapshot: any) => {
      if (snapshot.exists()) {
         callback(snapshot.val())
      }
   })
}
const updateRealtimeDB = async (path: string, data: Record<string, any>) => {
   // let projectId = store.getState().project.projectId;
   return update(ref(_realtimeDB, path), data)
}
const deleteRealtimeDB = async (path: string) => {
   return remove(ref(_realtimeDB, path))
}

export const realtimeDB = {
   readData,
   create: createRealtimeDB,
   read: readRealtimeDB,
   watch: watchRealtimeDB,
   update: updateRealtimeDB,
   delete: deleteRealtimeDB,
}

// const createFirestoreDB = async (assetType: string, data: Record<string, any>) => {
//    let projectId: string = store.getState().project.projectId
//    const docRef = doc(_firestoreDB, 'data', projectId)
//    return await setDoc(docRef, {
//       [assetType]: {
//          [data.uid]: data,
//       },
//    })
// }
// const readFirestoreDB = async (
//    assetType: string,
//    constraints: Tconstraint,
//    view: Record<string, string>
// ) => {
//    let projectId: string = store.getState().project.projectId
//    const docRef = doc(_firestoreDB, 'data', projectId)
//    const docSnap = await getDoc(docRef)
//    if (docSnap.exists()) {
//       return docSnap.data()?.[assetType] ?? null
//    }
//    return null
// }
// const watchFirestoreDB = async (
//    cb: (data: any) => void,
//    assetType: string,
//    constraints: Tconstraint,
//    view: Record<string, string>
// ) => {
//    let projectId: string = store.getState().project.projectId
//    const docRef = doc(_firestoreDB, 'data', projectId)
//    const docSnap = await getDoc(docRef)
//    if (docSnap.exists()) {
//       return docSnap.data()?.[assetType] ?? null
//    }
//    return null
// }
// const updateFirestoreDB = async (assetType: string, assetId: string, data: Record<string, any>) => {
//    let projectId: string = store.getState().project.projectId
//    const docRef = doc(_firestoreDB, 'data', projectId)
//    return await updateDoc(docRef, {
//       [`${assetType}.${assetId}`]: data,
//    })
// }
// const deleteFirestoreDB = async (assetType: string, assetId: string) => {
//    let projectId: string = store.getState().project.projectId
//    const docRef = doc(_firestoreDB, 'data', projectId)
//    return await updateDoc(docRef, {
//       [`${assetType}.${assetId}`]: deleteField(),
//    })
// }

// export const firestoreDB = {
//    create: createFirestoreDB,
//    read: readFirestoreDB,
//    watch: watchFirestoreDB,
//    update: updateFirestoreDB,
//    delete: deleteFirestoreDB,
// }

// Storage
const _storage = getStorage(app)
const uploadFile = async (path: string, fileURI: string) => {
   // Why are we using XMLHttpRequest? See:
   // https://github.com/expo/expo/issues/2402#issuecomment-443726662
   const blob: any = await new Promise((resolve: any, reject: any) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
         resolve(xhr.response)
      }
      xhr.onerror = function (e) {
         console.error('Error getting storage:', e)
         reject(new TypeError('Network request failed'))
      }
      xhr.responseType = 'blob'
      xhr.open('GET', fileURI, true)
      xhr.send(null)
   })

   const storageRef = sref(_storage, path)
   const result = await uploadBytes(storageRef, blob)

   // We're done with the blob, close and release it
   try {
      blob.close()
   } catch (e) {
      console.warn(e)
   }

   return await getDownloadURL(storageRef)
}
const uploadFromURL = async (path: string, fileURL: string) => {
   const res = await fetch(fileURL)
   const blob = await res.blob()
   const storageRef = sref(_storage, path)
   await uploadBytes(storageRef, blob)
   return getDownloadURL(storageRef)

   // fetch(
   //    fileURL
   // ).then(
   //    res => res.blob()
   // ).then(
   //    blob => {
   //       const storageRef = sref(_storage, path);
   //       uploadBytes(storageRef, blob);
   //    // firebase.storage().ref().child(filename).put(blob)
   //    }
   // )
}
const urlFromPath = async (path: string) => {
   const storageRef = sref(_storage, path)
   return getDownloadURL(storageRef)
}
const downloadFile = async (path: string) => {
   const storageRef = sref(_storage, path)
   const url = await getDownloadURL(storageRef)
   return url
}
const listFiles = async (path = '') => {
   const storageRef = sref(_storage, path)
   const listRef = sref(storageRef)
   const res = await listAll(listRef)
   return res.items
}
const deleteFile = async (path: string) => {
   const storageRef = sref(_storage, path)
   await deleteObject(storageRef)
}
export const storage = {
   uploadFile,
   uploadFromURL,
   urlFromPath,
   downloadFile,
   listFiles,
   deleteFile,
}

// Functions
const _functions = getFunctions(app)
const sendEmail = httpsCallable(_functions, 'sendEmail')
export const functions = {
   sendEmail,
}
