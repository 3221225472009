import { connect } from 'react-redux';

import Input from './Input';
import { Dispatch } from 'react';
import { updateLocalStorage } from "../../reducers/LocalDataSlice";

function mapStateToProps(state: Record<string, any>, params: any) {
   return {
      ...params
   };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
   // add methods here
   return {
      handleValueChanges: (text: string, uid: string) => {
         dispatch(updateLocalStorage({
            path: ['atoms', uid, 'computedProperties', 'input_value'],
            value: text,
         } as any));
      },
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(Input);
