import React, { useState, useEffect, useMemo } from 'react';
import { Pressable, View, LayoutChangeEvent, Text as RNText } from 'react-native';
import { useDispatch } from 'react-redux';
import { LinearGradient } from 'expo-linear-gradient';
import { Map, List } from 'immutable';

import Box from '../Box';
import Modal from '../Modal';
import BoxList from '../BoxList';
import Chart from '../Chart';
import Media from '../Media';
import Input from '../Input';
import Text from '../Text';
import {
   updateComponentLayout,
} from '../../reducers/LocalDataSlice';
import { parseComponentStyle, runWorkflow } from '../../lib';


interface IMetaComponentProps {
   component: Map<string, any>,
   parent: Map<string, any>,
   nestingAncestry?: any,
   onPressWorkflows: List<Map<string, any>>,
   onAlternativePressWorkflows: List<Map<string, any>>,
}
const MetaComponent = ({ 
   component, 
   parent, 
   nestingAncestry, 
   onPressWorkflows, 
   onAlternativePressWorkflows ,
}: IMetaComponentProps) => {
   const dispatch = useDispatch();

   // style parsing
   const [style, setStyle] = useState(dispatch(parseComponentStyle(
      component,
      parent,
   ) as unknown as any));
   useEffect(() => {
      setStyle(dispatch(parseComponentStyle(
         component,
         parent,
      ) as unknown as any));
   }, [component.get('computedStyle'), parent?.getIn(['computedStyle', 'layout', 'direction'])]);

   const onLayout = (event: LayoutChangeEvent) => {
      dispatch(updateComponentLayout({ componentId: component.get('uid'), layout: event.nativeEvent.layout }));
   };

   // On click workflows
   const handleOnPress = () => {
      onPressWorkflows.forEach((workflow: Map<string, any>) => {
         dispatch(runWorkflow(
            component.get('uid'),
            workflow.get('uid'),
            nestingAncestry,
         ) as any);
      });
   }

   // On alternative click workflows
   const handleAlternativePress = () => {
      onAlternativePressWorkflows.forEach((workflow: Map<string, any>) => {
         dispatch(runWorkflow(
            component.get('uid'),
            workflow.get('uid'),
            nestingAncestry,
         ) as any);
      });
   }

   // Component specifics
   const renderComponent = useMemo(() => {
      switch (component.get('type')) {
         case 'boxList':
            return (
               <BoxList
                  component={component}
                  style={style}
                  parent={parent}
                  nestingAncestry={nestingAncestry}
               />
            );
         case 'box':
            return (
               <Box                     
                  component={component}
                  style={style}
                  parent={parent}
                  nestingAncestry={nestingAncestry}
               />
            );
         case 'modal':            
            return (
               <Modal                     
                  component={component}
                  style={style}
                  parent={parent}
                  nestingAncestry={nestingAncestry}
               />
            );
         case 'text':
            return (
               <Text
                  component={component}
                  style={style}
                  parent={parent}
                  nestingAncestry={nestingAncestry}
               />
            );
         case 'media':
         case 'image':
         case 'video':
         case 'icon':
            return (
               <Media
                  component={component}
                  style={style}
                  parent={parent}
                  nestingAncestry={nestingAncestry}
               />
            );
         case 'input':
            return (
                  <Input
                     component={component}
                     style={style}
                     parent={parent}
                     nestingAncestry={nestingAncestry}
                  />
            );
         case 'chart':
            return (
               <Chart
                  component={component}
                  style={style}
                  parent={parent}
                  nestingAncestry={nestingAncestry}
               />
            );
         default:
            return <></>;
      }
   }, [parent, component]);

   if (component.getIn(['computedProperties', 'is_hidden']) == 'true' || component.getIn(['computedProperties', 'is_hidden']) == true) {
      return null;
   }

   const { text, layout, ...rest } = style;
   const Wrapper = (onPressWorkflows?.size > 0 || onAlternativePressWorkflows?.size > 0) ? Pressable : View;

   return (
      <>
         {rest.gradient ? (
            <View style={layout}>
               <LinearGradient style={{ ...rest }} {...rest.gradient}>
                  <Wrapper
                     style={!rest.gradient ? { ...rest } : {}}
                     onLayout={onLayout}
                     onPress={handleOnPress}
                     onLongPress={handleAlternativePress}
                  >
                     {renderComponent}
                  </Wrapper>
               </LinearGradient>
            </View>
         ) : (
            <Wrapper style={{ ...rest }}
               onLayout={onLayout}
               onPress={handleOnPress}
               onLongPress={handleAlternativePress}
            >
               {renderComponent}
            </Wrapper>
         )}
      </>
   );
};

export default MetaComponent;
