import React, { Dispatch, useLayoutEffect, useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { connect, useDispatch } from 'react-redux';
import { Map, List } from 'immutable'

import { regex } from '../../hooks';
import { ComponentStoreRecord, initializeComponent, refreshComponentStyle } from '../../reducers/LocalDataSlice'
import { TState } from '../../reducers';
import MetaComponent from './MetaComponent';
import InlineLogic from './InlineLogic';
import { getWorkflows, runWorkflow } from '../../lib';

interface IMetaContainer {
   nestingAncestry: List<any>,
   parent: Map<string, any>, // ComponentStoreRecord
   schemaComponent: Map<string, any>, // ComponentRecord,
   storeComponent: Map<string, any>, // ComponentStoreRecord,
   designSystemStyle: Map<string, any>, // ComponentStylingRecord,
   events: Map<string, any>,
}
export const MetaContainer = (props: IMetaContainer) => {
   const {
      schemaComponent,
      storeComponent,
      designSystemStyle,
      parent,
      nestingAncestry,
      events,
   } = props;
   // TODO: add hooks
   const dispatch = useDispatch();
   const windowDimensions = useWindowDimensions();
   
   // Component initialization
   useLayoutEffect(() => {
      dispatch(initializeComponent({
         component: schemaComponent,
         nestingAncestry,
         style: designSystemStyle
      }));
   }, [])

   // On start workflows
   useEffect(() => {
      const onStartWorkflows = getWorkflows(schemaComponent.get('logic', Map()), 'trigger.onStart');
      onStartWorkflows.forEach((workflow: Map<string, any>) => {
         dispatch(runWorkflow(
            schemaComponent.get('uid'),
            workflow.get('uid'),
            nestingAncestry,
         ) as any);
      });
   }, []);

   useEffect(() => {
      console.error('events_events_events', events)
      if(events.size > 0){
         const onStartWorkflows = getWorkflows(schemaComponent.get('logic', Map()), 'trigger.onEvent');

         if(onStartWorkflows.size > 0) {
            onStartWorkflows.forEach((workflow: Map<string, any>) => {
               dispatch(runWorkflow(
                  schemaComponent.get('uid'),
                  workflow.get('uid'),
                  nestingAncestry,
               ) as any);
            });
         }
      }
   }, [events]);

   // Visual logic
   useEffect(() => {
      if ((storeComponent ?? Map()).get('uid', '') !== '') {
         dispatch(refreshComponentStyle({
            componentId: storeComponent.get('uid'),
            passiveStyle: designSystemStyle.mergeDeep(schemaComponent.get('custom_styling', Map())),
            conditionalStyle: schemaComponent.get('visual_logic', Map()).filter(
               (_: any, index: number) => storeComponent.getIn(['visualConditions', index], false)
            ).toList().map(
               (visualLogic: Map<string, any>) => visualLogic.get('custom_styling', Map())
            ),
            passiveProperties: schemaComponent.get('properties', Map()),
            conditionalProperties: schemaComponent.get('visual_logic', Map()).filter(
               (_: any, index: number) => storeComponent.getIn(['visualConditions', index], false)
            ).toList().map(
               (visualLogic: Map<string, any>) => visualLogic.get('properties', Map())
            )
         }));
      }
   }, [(storeComponent ?? Map()).get('visualConditions', List())])

   // Conditional logic
   const [conditionLogic, setConditionLogic] = useState(Map());
   useEffect(() => {
      if (Map.isMap(storeComponent) && storeComponent.get('uid') === '') {
         storeComponent.get('logicConditions', Map())
            .filter(
               (logic: Map<string, any>, key: string) => logic && conditionLogic.get(key, false) !== logic
            )
            .forEach(
               (logic: Map<string, any>, key: string) => {
                  // TODO: dispatch runWorkflow similar to onClick workflow
                  // dispatch(useConditionLogic(logic, key, storeComponent.get('uid')));
               }
            )

         setConditionLogic(storeComponent.get('logicConditions', Map()));
      }
   }, [storeComponent && storeComponent.get('logicConditions', Map())])

   if (!storeComponent || storeComponent.get('uid') === '') {
      return null;
   }

   const dynamicProperties = schemaComponent.get('properties', Map()).filter(
      (propertyValue: any, propertyName: string) => {
         switch (typeof propertyValue) {
            case 'string':
               return regex.test(propertyValue)
            case 'object':
               return propertyName === 'list_of_items'
         }
      }
   )

   return (
      <>
         {/* Inline logic to compute data and properties */
            dynamicProperties.keySeq().map(
               (propertyName: string) => {
                  return <InlineLogic
                     key={propertyName}
                     expression={schemaComponent.getIn(['properties', propertyName]) as string | Map<string, any>}
                     localStoragePath={['atoms', storeComponent.get('uid'), 'computedProperties', propertyName]}
                     nestingAncestry={nestingAncestry}
                  />
               }
            )
         }
         {/* Visual logic */
            schemaComponent.get('visual_logic', List()).map(
               (logic: Map<string, any>, index: number) => {
                  return <InlineLogic
                     key={index}
                     expression={logic.get('condition')}
                     localStoragePath={['atoms', storeComponent.get('uid'), 'visualConditions', index]}
                     nestingAncestry={nestingAncestry}
                  />
               }
            )
         }
         {/* Conditional logic */
            getWorkflows(schemaComponent.get('logic', Map()), 'trigger.condition').keySeq().map(
               (logicId: string) => {
                  return <InlineLogic
                     key={logicId}
                     expression={schemaComponent.getIn(['logic', logicId, 'condition']) as Map<string, any>}
                     localStoragePath={['atoms', storeComponent.get('uid'), 'logicConditions', logicId]}
                     nestingAncestry={nestingAncestry}
                  />
               }
            )
         }

         <MetaComponent
            nestingAncestry={nestingAncestry}
            parent={parent}
            component={storeComponent}
            onPressWorkflows={getWorkflows(schemaComponent.get('logic', Map()), 'trigger.onClick').toList()}
            onAlternativePressWorkflows={getWorkflows(schemaComponent.get('logic', Map()), 'trigger.onAlternateClick').toList()}
         /> 
      </>
   )
}

function mapStateToProps(
   state: TState,
   { 
      componentId, parent, nestingAncestry
   }: {
      componentId: string,
      parent?: Map<string, any>
      nestingAncestry: List<any>,
   }
) {
   const schemaComponent = state.project.getIn(['definition', 'atoms', componentId]) as Map<string, any> ?? Map();
   const storeComponent = state.local.getIn([
      'atoms',
      componentId + nestingAncestry.map((cell: any) => cell.get('suffix')).join('')
   ]) as Map<string, any> ?? Map();
   const designSystemStyle = state.project.getIn(
      [
         'definition',
         'design_system',
         'user_styles',
         schemaComponent.get('style'),
         'styling'
      ]
   ) as Map<string, any> ?? Map();

   const events = state.events.getIn(['events']) as Map<string, any> ?? Map();

   return {
      parent: parent ?? Map(),
      nestingAncestry: nestingAncestry ?? List(),
      schemaComponent,
      storeComponent,
      designSystemStyle,
      events,
   }
}

export default connect(mapStateToProps, null)(MetaContainer)
