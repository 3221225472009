import React, { useEffect, useState } from 'react';
import { TextInput as RNTextInput } from 'react-native';
import { useDispatch } from 'react-redux';
import { List, Map } from 'immutable';

import { updateComponentData } from '../../reducers/LocalDataSlice';
import { validateField } from '../../lib';

// type TProps = TInput & TCreateComponentFuncAsProp & Pick<TextInputProps, 'style'>;

type ITextProps = {
   component: Map<string, any> // ComponentStoreRecord,
   parent?: Map<string, any>, // ComponentStoreRecord,
   nestingAncestry: List<any>,
   handleValueChanges: any,
   handleTextChanges?: any
}

const TextInput = ({
   component,
   parent,
   nestingAncestry,
   handleValueChanges,
   handleTextChanges
}: ITextProps) => {
   const dispatch = useDispatch();

   const uid = component.get('uid');
   const placeholder = component.getIn(['properties', 'input', 'placeholder'], '') as string;
   const type = component.getIn(['data', 'input', 'type'], 'text') as string;
   const input_value = component.getIn(['data', 'input_value'], '') as string;

   const [inputText, setInputText] = useState('');
   const [isValid, setIsValid] = useState(true);

   useEffect(() => {
      if (input_value) {
         const regex = /(@@[\w\W]*@@)/;
         if (regex.test(input_value))
            handleValueChanges(input_value, uid, nestingAncestry.last().get('rootId'));

         setInputText(input_value);
      }
   }, [nestingAncestry, input_value]);

   const validateText = (text: string) => {
      setInputText(text);

      const isValid = validateField(type, text);

      if (isValid) setIsValid(true);
      else setIsValid(false);

      dispatch(updateComponentData({ uid, key: 'input_value', value: text } as any));
      dispatch(updateComponentData({ uid, key: 'isValid', value: isValid } as any));
   };

   return (
      <RNTextInput
         value={inputText}
         onChangeText={(text) => validateText(text)}
         placeholderTextColor='grey'
         style={[
            { flex: 1, outlineStyle: 'none' },
            // { ...calcWH(style, parentLayout) },
            !isValid ? { borderColor: 'red', borderWidth: 2 } : {},
            (component.getIn(['computedStyle', 'text'], Map()) as Map<string, any>).toJS() as Record<string, any>,
         ]}
         placeholder={placeholder}
      />
   );
};

export default TextInput;
