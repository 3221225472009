import React, { Dispatch } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Map, List } from 'immutable';

import { editorOnPressLogic, editorSelectLogic } from '../../lib/editorHelper/defaultVisualLogic';
import ScreenComponent from './ScreenComponent';
import { TScreen } from '../../types';
import { ComponentRecord } from '../../types/records'

function mapStateToProps(
   state: Record<string, any>,
   { 
    navigation,
    screen,
    storeLayout,
    displayType,
   }: {
    navigation?: Navigator
    screen?: any
    displayType: string,
    storeLayout?: {
        width: number;
        height: number;
        x: number;
        y: number;
    }
   }
) {
   const appReady: boolean = state.local?.getIn(['loaded']);
   
   return {
        navigation,
        screen,
        storeLayout,
        displayType,
        appReady
    }
}

// TODO: typing
function mapDispatchToProps(dispatch: Dispatch<any>) {
   return { };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenComponent as any)
