import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useFonts } from 'expo-font';
import React, { useEffect, useState } from 'react';
import { Text } from 'react-native'
import { useDispatch, useSelector } from 'react-redux';
import { Map } from "immutable"

import Loader from './components/Loader';
import { ErrorBoundary } from './components/ErrorBoundary';
import { navigationRef } from './lib/navigate';
import { setProjectId, setProjectDefinition } from './reducers/ProjectSlice';
import { DesignSystemFontsRecord, ScreenRecord } from './types/records/schema';
import Screen from './components/Screen';

const Stack = createStackNavigator();
export const Entrypoint = ({schema, projectId }: {schema: Map<string, any>, projectId: string }) => {
   const dispatch = useDispatch();
   const fontsToLoad = Array.from(
      (
         schema?.getIn(['design_system', 'fonts'], Map()) as Map<string, DesignSystemFontsRecord> ?? Map()
      ).values()
   ).reduce(
         (acc: Record<string, string>, font: DesignSystemFontsRecord) => ({
            ...acc,
            [font.get('name')]: font.get('font', '').replace('http://', 'https://')
         }
      ),
      {}
   )

   useEffect(() => {

      dispatch(setProjectId(projectId));
   }, [])
   useEffect(() => {
      dispatch(setProjectDefinition(schema))
   }, [schema])

   const [fontsLoaded] = useFonts(fontsToLoad);

   if (!fontsLoaded) {

      return <Loader />;
   }

   console.log(schema.toJS())

   return (
      <NavigationContainer ref={navigationRef}>
         <Stack.Navigator initialRouteName={schema?.get('mainscreen')} screenOptions={{ headerShown: false }}>
            {
               schema?.get('screens').valueSeq().map((screen: ScreenRecord) => (
                  <Stack.Screen
                     key={screen.get('uid')}
                     name={screen.get('uid')}>
                     {(props) => (
                        <Screen
                           screen={screen}
                           displayType={'desktop'}
                           {...props} />)}
                  </Stack.Screen>
                  )
               )
            }
         </Stack.Navigator>
      </NavigationContainer>
   );
};

export default Entrypoint;
