import { connect } from 'react-redux';
import { Map, List } from 'immutable';

import BoxList from './BoxListComponent';

export interface IBoxListContainerProps {
   component: Map<string, any>,
   parent: Map<string, any>,
   style: any,
   nestingAncestry: any,
}
function mapStateToProps(
   state: Record<string, any>,
   params: IBoxListContainerProps
) {
   const {
      component,
      parent,
      style,
      nestingAncestry,
   } = params

   // retrieve list of items
   const listItems = component.getIn(['computedProperties', 'list_of_items'], []) as any[];
   // resolve current cell root component information
   const cellRoot = component.get('children', List());
   const cellRootId = cellRoot.first();

   return {
      component,
      parent,
      style,
      cellRootId,
      listItems,
      nestingAncestry,
   };
}

export default connect(mapStateToProps, null)(BoxList);
