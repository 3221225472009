import { Record, RecordOf, Map } from 'immutable'


type Unit = 'rf' | 'px' | '%' | 'vh' | 'vw' // rf = responsive factor. Default unit is rf almost everywhere
type TBorderStyle = 'solid' | 'dashed' | 'none'
type TPositionAlignmentOptions = 'start' | 'center' | 'end' | 'stretch'


// Numerical value
type TNumericalValue = {
   val: string // either inline-logic or number as string
   unit: Unit
}
const defaultNumberValues: TNumericalValue = {
   val: '0',
   unit: 'rf'
}
export const NumericalValueRecord: Record.Factory<TNumericalValue> = 
   Record(defaultNumberValues)
export type NumericalValueRecord = RecordOf<TNumericalValue>


// Border
type TComponentBorder = {
   width: NumericalValueRecord
   color: string
   style: TBorderStyle
}
const defaultBorderSegmentValues: TComponentBorder = {
   width: NumericalValueRecord(),
   color: 'black',
   style: 'solid',
}
export const ComponentBorderSegmentRecord: Record.Factory<TComponentBorder> = 
   Record(defaultBorderSegmentValues)
export type ComponentBorderSegmentRecord = RecordOf<TComponentBorder>
const defaultBorderValues: TComponentSpacing<TComponentBorder> = {
   top: ComponentBorderSegmentRecord(),
   right: ComponentBorderSegmentRecord(),
   bottom: ComponentBorderSegmentRecord(),
   left: ComponentBorderSegmentRecord(),
}
export const ComponentBorderRecord: Record.Factory<TComponentSpacing<TComponentBorder>> = 
   Record(defaultBorderValues)
export type ComponentBorderRecord = RecordOf<TComponentSpacing<TComponentBorder>>


// Spacing
type TComponentSpacing<T = NumericalValueRecord> = {
   top?: T
   right?: T
   bottom?: T
   left?: T
}
const defaultSpacingValues: TComponentSpacing = {
   top: NumericalValueRecord(),
   right: NumericalValueRecord(),
   bottom: NumericalValueRecord(),
   left: NumericalValueRecord(),
}
export const ComponentSpacingRecord: Record.Factory<TComponentSpacing> = 
   Record(defaultSpacingValues)
export type ComponentSpacingRecord<T = NumericalValueRecord> = RecordOf<TComponentSpacing<T>>


// Shadow Styling
type TShadowStyling = {
   color?: string
   horizontal?: NumericalValueRecord
   vertical?: NumericalValueRecord
   blur?: NumericalValueRecord
}
const defaultShadowStylingValues: TShadowStyling = {
   color: 'black',
   horizontal: NumericalValueRecord(),
   vertical: NumericalValueRecord(),
   blur: NumericalValueRecord(),
}
export const ComponentShadowStylingRecord: Record.Factory<TShadowStyling> = 
   Record(defaultShadowStylingValues)
export type ComponentShadowStylingRecord = RecordOf<TShadowStyling>


// Text Styling
type TComponentTextStyling = {
   color?: string
   alignment?: 'left' | 'center' | 'right' | 'justify'
   size?: NumericalValueRecord
   font?: string
   line_height?: string
   weight?: NumericalValueRecord
   shadow?: ComponentShadowStylingRecord
}
const defaultTextStylingValues: TComponentTextStyling = {
   color: 'black',
   alignment: 'left',
   size: NumericalValueRecord({ val: '1'}),
   font: 'serif',
   line_height: '1',
   weight: NumericalValueRecord(),
   shadow: ComponentShadowStylingRecord(),
}
export const ComponentTextStylingRecord: Record.Factory<TComponentTextStyling> = 
   Record(defaultTextStylingValues)
export type ComponentTextStylingRecord = RecordOf<TComponentTextStyling>


// Width section
type TComponentWidthStyling = {
   type?: 'fit' | 'expand' | 'fixed' // expand by default
   min?: NumericalValueRecord
   max?: NumericalValueRecord
   fixed?: NumericalValueRecord
}
const defaultWidthStylingValues: TComponentWidthStyling = {
   type: 'expand',
   min: NumericalValueRecord(),
   max: NumericalValueRecord({ val: '100', unit: '%'}),
   fixed: NumericalValueRecord({ val: '10' }),
}
export const ComponentWidthStylingRecord: Record.Factory<TComponentWidthStyling> = 
   Record(defaultWidthStylingValues)
export type ComponentWidthStylingRecord = RecordOf<TComponentWidthStyling>


// Ratio styling
type TRatioStyling = {
   width?: string
   height: string
}
const defaultRatioStylingValues: TRatioStyling = {
   width: '1',
   height: '1',
}
export const ComponentRatioStylingRecord: Record.Factory<TRatioStyling> = 
   Record(defaultRatioStylingValues)
export type ComponentRatioStylingRecord = RecordOf<TRatioStyling>


// Height section
type TComponentHeightStyling = {
   type?: 'fit' | 'expand' | 'fixed' | 'ratio' // fit by default
   min?: NumericalValueRecord
   max?: NumericalValueRecord
   fixed?: NumericalValueRecord
   ratio?: ComponentRatioStylingRecord
}
const defaultHeightStylingValues: TComponentHeightStyling = {
   type: 'fit',
   min: NumericalValueRecord(),
   max: NumericalValueRecord(),
   fixed: NumericalValueRecord(),
   ratio: ComponentRatioStylingRecord(),
}
export const ComponentHeightStylingRecord: Record.Factory<TComponentHeightStyling> = 
   Record(defaultHeightStylingValues)
export type ComponentHeightStylingRecord = RecordOf<TComponentHeightStyling>


// Border Radius section
type TComponentRadiusStyling = {
   top_left?: NumericalValueRecord
   top_right?: NumericalValueRecord
   bottom_left?: NumericalValueRecord
   bottom_right?: NumericalValueRecord
}
const defaultRadiusStylingValues: TComponentRadiusStyling = {
   top_left: NumericalValueRecord(),
   top_right: NumericalValueRecord(),
   bottom_left: NumericalValueRecord(),
   bottom_right: NumericalValueRecord(),
}
export const ComponentRadiusStylingRecord: Record.Factory<TComponentRadiusStyling> = 
   Record(defaultRadiusStylingValues)
export type ComponentRadiusStylingRecord = RecordOf<TComponentRadiusStyling>


// Layout section
type TComponentLayoutStyling = {
   direction?: 'row' | 'column' | 'rowWrap' // column by default
   column_gap?: NumericalValueRecord
   row_gap?: NumericalValueRecord
   distribution?: 'between' | 'start' | 'center' | 'end' | 'evenly' // between by default
   alignment?: TPositionAlignmentOptions
}
const defaultLayoutStylingValues: TComponentLayoutStyling = {
   direction: 'column',
   column_gap: NumericalValueRecord(),
   row_gap: NumericalValueRecord(),
   distribution: 'between',
   alignment: 'start',
}
export const ComponentLayoutStylingRecord: Record.Factory<TComponentLayoutStyling> = 
   Record(defaultLayoutStylingValues)
export type ComponentLayoutStylingRecord = RecordOf<TComponentLayoutStyling>


// Position auto
type TComponentPositionAutoStyling = {
   cross_axis?: TPositionAlignmentOptions
   position: TPositionAlignmentOptions
}
const defaultPositionAutoStylingValues: TComponentPositionAutoStyling = {
   cross_axis: 'center',
   position: 'center',
}
export const ComponentPositionAutoStylingRecord: Record.Factory<TComponentPositionAutoStyling> = 
   Record(defaultPositionAutoStylingValues)
export type ComponentPositionAutoStylingRecord = RecordOf<TComponentPositionAutoStyling>


// Position relative to parent
type TComponentPositionRelativeStyling = {
   horizontal?: TPositionAlignmentOptions
   vertical?: TPositionAlignmentOptions
   top?: NumericalValueRecord
   right?: NumericalValueRecord
   bottom?: NumericalValueRecord
   left?: NumericalValueRecord
}
const defaultPositionRelativeStylingValues: TComponentPositionRelativeStyling = {
   horizontal: 'center',
   vertical: 'center',
   top: NumericalValueRecord({unit: '%'}),
   right: NumericalValueRecord({unit: '%'}),
   bottom: NumericalValueRecord({unit: '%'}),
   left: NumericalValueRecord({unit: '%'}),
}
export const ComponentPositionRelativeStylingRecord: Record.Factory<TComponentPositionRelativeStyling> = 
   Record(defaultPositionRelativeStylingValues)
export type ComponentPositionRelativeStylingRecord = RecordOf<TComponentPositionRelativeStyling>


// Position fixed to screen
type TComponentPositionFixedStyling = {
   top?: NumericalValueRecord
   right?: NumericalValueRecord
   bottom?: NumericalValueRecord
   left?: NumericalValueRecord
}
const defaultPositionFixedStylingValues: TComponentPositionFixedStyling = {
   top: NumericalValueRecord({unit: '%'}),
   right: NumericalValueRecord({unit: '%'}),
   bottom: NumericalValueRecord({unit: '%'}),
   left: NumericalValueRecord({unit: '%'}),
}
export const ComponentPositionFixedStylingRecord: Record.Factory<TComponentPositionFixedStyling> = 
   Record(defaultPositionFixedStylingValues)
export type ComponentPositionFixedStylingRecord = RecordOf<TComponentPositionFixedStyling>


// Position styling
type TComponentPositionStyling = {
   order?: string
   type?: 'auto' | 'relative' | 'fixed' // auto by default
   auto?: ComponentPositionAutoStylingRecord
   relative?: ComponentPositionRelativeStylingRecord
   fixed?: ComponentPositionFixedStylingRecord
}
const defaultPositionStylingValues: TComponentPositionStyling = {
   order: '0',
   type: 'auto',
   auto: ComponentPositionAutoStylingRecord(),
   relative: ComponentPositionRelativeStylingRecord(),
   fixed: ComponentPositionFixedStylingRecord(),
}
export const ComponentPositionStylingRecord: Record.Factory<TComponentPositionStyling> = 
   Record(defaultPositionStylingValues)
export type ComponentPositionStylingRecord = RecordOf<TComponentPositionStyling>


// Icon properties/styling
type TIconProperties = {
   type: 'FontAwesome' | 'MaterialIcons' | 'Ionicons' | 'svg'
   src: string
   color?: string
   size?: number
}
const defaultIconValues: TIconProperties = {
   type: 'FontAwesome',
   src: '',
   color: 'black',
   size: 24
}
export const IconRecord: Record.Factory<TIconProperties> = Record(defaultIconValues)
export type IconRecord = RecordOf<TIconProperties>


// Image styling
type TComponentImageStyling = {
   src: string
   //  position?: TPositionAlignmentOptions
   cover?: boolean // default = true
   repeat?: boolean // default = false
}
const defaultImageStylingValues: TComponentImageStyling = {
   src: '',
   //  position?: TPositionAlignmentOptions
   cover: true,
   repeat: false,
}
export const ComponentImageStylingRecord: Record.Factory<TComponentImageStyling> = 
   Record(defaultImageStylingValues)
export type ComponentImageStylingRecord = RecordOf<TComponentImageStyling>


// Linear Gradient property styling
type TComponentLinearGradientStyling = {
   direction?: 'to bottom' | 'to left' | 'to right' | 'to top'
}
const defaultLinearGradientStylingValues: TComponentLinearGradientStyling = {
   direction: 'to right'
}
export const ComponentLinearGradientStylingRecord: Record.Factory<TComponentLinearGradientStyling> = 
   Record(defaultLinearGradientStylingValues)
export type ComponentLinearGradientStylingRecord = RecordOf<TComponentLinearGradientStyling>


// Radial Gradient property styling
type TComponentRadialGradientStyling = {
   shape?: 'ellipse' | 'circle'
   size?: 'farthest-corner' | 'closest-corner' | 'farthest-side' | 'closest-side'
   position: 'center' | 'top' | 'right' | 'bottom' | 'left'
   // center_x?: string
   // center_y?: string
}
const defaultRadialGradientStylingValues: TComponentRadialGradientStyling = {
   shape: 'circle',
   size: 'farthest-corner',
   position: 'center',
}
export const ComponentRadialGradientStylingRecord: Record.Factory<TComponentRadialGradientStyling> = 
   Record(defaultRadialGradientStylingValues)
export type ComponentRadialGradientStylingRecord = RecordOf<TComponentRadialGradientStyling>


// Gradient styling
type TComponentGradientBackgroundStyling = {
   type?: 'linear' | 'radial'
   colors?: string[]
   linear?: ComponentLinearGradientStylingRecord
   radial?: ComponentRadialGradientStylingRecord
}
const defaultGradientStylingValues: TComponentGradientBackgroundStyling = {
   type: 'linear',
   colors: ['black', 'white'],
   linear: ComponentLinearGradientStylingRecord(),
   radial: ComponentRadialGradientStylingRecord(),
}
export const ComponentGradientStylingRecord: Record.Factory<TComponentGradientBackgroundStyling> = 
   Record(defaultGradientStylingValues)
export type ComponentGradientStylingRecord = RecordOf<TComponentGradientBackgroundStyling>


// Background styling
type TComponentBackgroundStyling = {
   type?: 'color' | 'image' | 'gradient' | 'none' // none by deault
   image?: ComponentImageStylingRecord
   color?: string // hex, rgb, rgba, string (blue etc) "{{[{getProperty('color')}]}}"
   gradient?: ComponentGradientStylingRecord
}
const defaultBackgroundStylingValues: TComponentBackgroundStyling = {
   type: 'none',
   image: ComponentImageStylingRecord(),
   color: '#FFF0',
   gradient: ComponentGradientStylingRecord(),
}
export const ComponentBackgroundStylingRecord: Record.Factory<TComponentBackgroundStyling> = 
   Record(defaultBackgroundStylingValues)
export type ComponentBackgroundStylingRecord = RecordOf<TComponentBackgroundStyling>


// Component styling
type TComponentStyling = {
   icon?: IconRecord
   width?: ComponentWidthStylingRecord
   height?: ComponentHeightStylingRecord
   margin?: ComponentSpacingRecord
   padding?: ComponentSpacingRecord
   border?: ComponentBorderRecord
   radius?: ComponentRadiusStylingRecord
   clip?: boolean // hide overflow, default = false
   layout?: ComponentLayoutStylingRecord
   position?: ComponentPositionStylingRecord
   background?: ComponentBackgroundStylingRecord
   shadow?: ComponentShadowStylingRecord
   text?: ComponentTextStylingRecord
   placeholder?: ComponentTextStylingRecord
}
const defaultStylingValues: TComponentStyling = {
   icon: IconRecord(),
   width: ComponentWidthStylingRecord(),
   height: ComponentHeightStylingRecord(),
   margin: ComponentSpacingRecord(),
   padding: ComponentSpacingRecord(),
   border: ComponentBorderRecord(),
   radius: ComponentRadiusStylingRecord(),
   clip: false,
   layout: ComponentLayoutStylingRecord(),
   position: ComponentPositionStylingRecord(),
   background: ComponentBackgroundStylingRecord(),
   shadow: ComponentShadowStylingRecord(),
   text: ComponentTextStylingRecord(),
   placeholder: ComponentTextStylingRecord(),
}
export const ComponentStylingRecord: Record.Factory<TComponentStyling> = 
   Record(defaultStylingValues)
export type ComponentStylingRecord = RecordOf<TComponentStyling>
