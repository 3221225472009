import { Map, Record, fromJS } from "immutable"


const mapOfRecords = (jsonMap: any, recordType: Record.Factory<any>) => {
   return Map(Object.keys(jsonMap || {}).reduce(
      (acc, id) => ({
         ...acc,
         [id]: recordType(jsonMap[id])
      }),
      {}
   ))
}

export const parseSchema = (JSONSchema: any): Map<string, any> => {
   // const immutableSchema = Map({
   //    atoms: mapOfRecords(JSONSchema.atoms, ComponentRecord),
   //    designSystem: DesignSystemRecord().mergeDeep(
   //       Map({
   //          responsive_facor: ResponsiveFactorRecord(JSONSchema.design_system.responsive_factor),
   //          colours: mapOfRecords(JSONSchema?.design_system?.colours, DesignSystemColourRecord),
   //          fonts: mapOfRecords(JSONSchema?.design_system?.fonts, DesignSystemFontsRecord),
   //          user_styles: mapOfRecords(JSONSchema?.design_system?.user_styles, ComponentStylingRecord),
   //       })
   //    ),
   //    page_index: JSONSchema.page_index,
   //    pages: mapOfRecords(JSONSchema.pages, ScreenRecord),
   //    data_model: mapOfRecords(JSONSchema.pages, DataSchemaRecord),
   // })
   // const schema = SchemaRecord().mergeDeep(immutableSchema)

   // return schema
   return fromJS(JSONSchema)
}