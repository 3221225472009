import { createSlice } from '@reduxjs/toolkit';
import { Map } from 'immutable'

export type TGlobalsSlice = Map<string, any>
const initialState: TGlobalsSlice = Map({
   gptModal: false
})
const globalsSlice = createSlice({
   name: 'globals',
   initialState,
   reducers: {
      setGlobalVar: (state: Record<string, any>, action: {payload: {key: string, value: string}}): any => {
         const { key, value } = action.payload;
         return state.set(key, value)
      },
   },
});

export const GlobalsReducer = globalsSlice.reducer;
export const { setGlobalVar } = globalsSlice.actions;
